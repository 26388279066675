import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ContactInfoRow(){
  return (
    <Row className="mb-3">
      <Col xs={6}>
        ph: <a href="tel:312-782-0614">312-782-0614</a><br/>
        ph: <a href="tel:312-419-8021">312-419-8021</a><br/>
        fax: <a href="tel:312-782-4142">312-782-4142</a><br/>
        email: <a href="mailto:Diagem@diageminc.net">Diagem@diageminc.net</a><br/>
      </Col>
      <Col xs={6}>
        Diagem Inc.<br/>
        29 E. Madison St. #1304<br/>
        Chicago, IL 60602<br/>
      </Col>
    </Row>
  );
}

export default ContactInfoRow;
