import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import UserAccountEditForm from './UserAccountEditForm';
import SetPasswordForm from '../../../Components/SetPasswordForm';
import { apiFetch } from '../../../Util';

function UserAccountEdit(props){
  const { userAccountId } = props;
  const [ user, setUser ] = useState(null);

  useEffect(() => {
    apiFetch
      .read(['user_account'], userAccountId)
      .then((result) => {
        setUser(result.read);
      });
  }, [userAccountId]);

  return (
    user === null ?
      <Spinner /> :
      <div>
        <UserAccountEditForm user={user} setUser={setUser} />
        <br/><br/>
        <SetPasswordForm user={user} />
      </div>
  );
}

export default UserAccountEdit;
