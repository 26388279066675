import React from 'react';
import SiteContentMarkdown from '../../Components/SiteContentMarkdown';
import ContentPage from '../../Layouts/ContentPage';

function Jewelry(){
  return (
    <ContentPage title="Jewelry Listing">
      <SiteContentMarkdown contentId="jewelry_content" />
    </ContentPage>
  );
}

export default Jewelry;
