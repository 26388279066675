import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Redirect } from 'react-router-dom';
import { apiFetch } from '../../Util';
import CardPage from '../../Layouts/CardPage';
import IfLoggedIn from '../../Components/IfLoggedIn';
import FormAlert from '../../Components/FormAlert';
import LabelRequired from '../../Components/LabelRequired';

const { Control, File, Group, Label, Text } = Form;

const INITIAL_MESSAGE = null;
  // 'Apply for an account. We will reply shortly with login information.';
const THANK_YOU_MESSAGE =
  "Thank you for registering. We will email you when your account is approved.";
const NULL_FUNCTION = () => {};
const SIZE_LIMIT = 2000000;

function Register(){
  const [input, setInput] = useState({});
  const [fileAttachments, setFileAttachments] = useState([]);
  const [status, setStatus] = useState({
    alert: 'primary',
    message: INITIAL_MESSAGE
  });
  const [validated, setValidated] = useState(false);

  function handleFileInputChange(e){
    setFileAttachments(e.currentTarget.files);
  }

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  function checkFileAttachments(reportError = NULL_FUNCTION){
    for(let fileAttachment of fileAttachments){
      if(fileAttachment.size > SIZE_LIMIT){
        reportError(
          `File attachment "${fileAttachment.name}" too large. Size limit is ${SIZE_LIMIT} bytes.`
        );
        return false;
      }
    }
    return true;
  }

  let disabled = status.alert === 'success';
  let finished = status.alert === 'success';

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if(!checkFileAttachments(window.alert)){
      return false;
    }
    if (form.checkValidity()) {
      try {
        disabled = true;
        const createResponse = await apiFetch.create(['account_info'], input);
        const newAccountInfo = createResponse.created;
        if(fileAttachments.length > 0){
          await apiFetch.upload(['account_info'], newAccountInfo.id, fileAttachments);
        }
        setStatus({
          alert: 'success',
          message: THANK_YOU_MESSAGE
        });
      } catch (e) {
        if(e.httpStatus === 422){
          setStatus({
            alert: 'danger',
            message: `Error: ${e}`
          });
        }
        else {
          setStatus({
            alert: 'danger',
            message: `A server error occurred while submitting your registration: ${e}`
          });
        }
      }
    }
    else {
      setStatus({
        alert: 'warning',
        message: 'Please complete all required fields before submitting the form'
      });
    }
    setValidated(true);
  };

  function fileAttachmentsLabel(){
    if(fileAttachments.length === 0){
      return "Drop files here to attach";
    }
    return `${fileAttachments.length} Attachments`;
  }

  function fileAttachmentValidityAttribs(){
    if(fileAttachments.length === 0){
      return {};
    }
    else if(!checkFileAttachments()){
      return {isInvalid: true};
    }
    return {};
  }

  return (
    <CardPage title="Register">
      <IfLoggedIn>
        <Redirect to="/" />
      </IfLoggedIn>
      { (status.message !== null) &&
        <FormAlert variant={status.alert}>
          {status.message}
        </FormAlert>
      }
      { !finished &&
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Group className="border-bottom border-primary py-2">
            <Group>
              <LabelRequired>Email Address</LabelRequired>
              <Control
                type="email"
                onChange={handleInputChange}
                name="email_address"
                disabled={disabled}
                autoComplete="email"
                required
              />
            </Group>
            <Group>
              <LabelRequired>New Account Password</LabelRequired>
              <Control
                type="password"
                onChange={handleInputChange}
                name="new_password"
                disabled={disabled}
                autoComplete="password"
                required
              />
            </Group>
          </Group>

          <Group className="border-bottom border-primary py-2">
            <Group>
              <LabelRequired>Full Name</LabelRequired>
              <Control
                type="text"
                onChange={handleInputChange}
                name="full_name"
                disabled={disabled}
                autoComplete="name"
                required
              />
            </Group>
            <Group>
              <LabelRequired>Company Name</LabelRequired>
              <Control
                type="text"
                onChange={handleInputChange}
                name="company_name"
                disabled={disabled}
                required
              />
            </Group>
            <Group>
              <LabelRequired>Mailing Address</LabelRequired>
              <Control
                as="textarea"
                onChange={handleInputChange}
                rows="3"
                name="mailing_address"
                disabled={disabled}
                autoComplete="street-address"
                required
              />
            </Group>
            <Group>
              <LabelRequired>Phone Number</LabelRequired>
              <Control
                type="tel"
                onChange={handleInputChange}
                name="phone_number"
                disabled={disabled}
                autoComplete="tel"
                required
              />
            </Group>
          </Group>

          <Group>
            <Group>
              <Label>Business References</Label>
              <Control
                as="textarea"
                onChange={handleInputChange}
                rows="3"
                name="business_references"
                disabled={disabled}
              />
            </Group>
            <Group>
              <Label>Document Upload</Label>
              <File
                onChange={handleFileInputChange}
                label={ fileAttachmentsLabel() }
                data-browse="Select file..."
                custom
                multiple
                accept=".pdf, .txt, .docx, .doc, .xls, .rtf, .html"
                {... fileAttachmentValidityAttribs() }
              />
              <Text>
                Upload files
                with <strong>know your customer</strong> information
                or <strong>business references</strong>.
              </Text>
            </Group>
            <Group>
              <Label>JBT Number</Label>
              <Control
                type="text"
                onChange={handleInputChange}
                name="jbt_number"
                disabled={disabled}
              />
            </Group>
            <Group>
              <Label>Tax ID / Retailer Certification Number</Label>
              <Control
                type="text"
                onChange={handleInputChange}
                name="business_id_number"
                disabled={disabled}
              />
            </Group>
            <Group>
              <Label>Years in Business</Label>
              <Control
                type="number"
                onChange={handleInputChange}
                name="years_in_business"
                disabled={disabled}
              />
            </Group>
            <Button
              variant="primary"
              type="submit"
              disabled={disabled}
            >Submit</Button>
          </Group>
        </Form>
      }
    </CardPage>
  );
}

export default Register;
