import CardPage from '../../Layouts/CardPage';
import LoginForm from './LoginForm';
import React from 'react';
import { sessionUserContext } from '../../Context/sessionUserContext';
import { Redirect } from 'react-router-dom';

function Login(){
  return (
    <CardPage title="Sign In">
      <sessionUserContext.Consumer>
        {({logInSession, session, userAccount}) => (
          (session && userAccount) ?
            <Redirect to="/" /> :
            <LoginForm logInSession={logInSession} />
        )}
      </sessionUserContext.Consumer>
    </CardPage>
  );
}

export default Login;
