import React from 'react';
import Button from 'react-bootstrap/Button';
import { apiFetch } from '../../../../../../../Util';


function AttachmentList(props){
  const { fileList } = props;
  if(!fileList){
    return 'No Attachments';
  }
  function namePart(fileName){
    const parts = fileName.split('/');
    return parts[parts.length - 1];
  }
  async function startDownload(fileName){
    const url = `/api/file/${fileName}`;
    const options = {
      method: 'GET',
      headers: apiFetch.authorizationHeader(),
      mode: 'cors'
    };
    const response = await fetch(url, options);
    const responseDataBlob = await response.blob();
    const objectUrl = URL.createObjectURL(responseDataBlob);
    const tmpElement = document.createElement("a");
    tmpElement.style.display = "none";
    document.body.appendChild(tmpElement);
    tmpElement.href = objectUrl;
    tmpElement.setAttribute("download", fileName);
    tmpElement.click();
    URL.revokeObjectURL(tmpElement.href);
    document.body.removeChild(tmpElement);
  }
  return (
    <>
      { fileList.map((fileName) =>
        <dd key={fileName}>
          <Button variant="outline-secondary" onClick={() => startDownload(fileName)}>
            {namePart(fileName)}
          </Button>{ }
        </dd>
      )}
    </>
  );
}

export default AttachmentList;
