import React from 'react';
import CardPage from '../../Layouts/CardPage';
import { Redirect } from 'react-router-dom';
import PasswordResetForm from './PasswordResetForm';
import IfLoggedIn from '../../Components/IfLoggedIn';
import IfLoggedOut from '../../Components/IfLoggedOut';
import SessionTokenRedirect from './SessionTokenRedirect';
import { sessionUserContext } from '../../Context/sessionUserContext';

function PasswordReset(props){
  const { match } = props;
  if(match.params.token){
    const token = decodeURIComponent(match.params.token);
    return (
      <sessionUserContext.Consumer>
        {({logInSession}) => (
          <SessionTokenRedirect logInSession={logInSession} token={token} />
        )}
      </sessionUserContext.Consumer>
    );
  }
  return (
    <CardPage title="Password Reset">
      <IfLoggedIn>
        <Redirect to="/my-account" />
      </IfLoggedIn>
      <IfLoggedOut>
        <PasswordResetForm />
      </IfLoggedOut>
    </CardPage>
  );
}

export default PasswordReset;
