/**
 * Function to traverse a JS object and change keys from one naming scheme
 * to another.
 */

function isObject(v){
  return v !== null && v !== undefined && typeof v === 'object';
}

function inflectResponse(responseJson, inflectName){
  const inflectedJson = {};
  if(typeof responseJson === 'string'){
    return responseJson;
  }
  for(let [k, v] of Object.entries(responseJson)){
    k = inflectName(k);
    if(Array.isArray(v)){
      v = v.map((item) => inflectResponse(item, inflectName));
    }
    else if(isObject(v)){
      v = inflectResponse(v, inflectName);
    }
    inflectedJson[k] = v;
  }
  return inflectedJson;
}

export default inflectResponse;
