import React, { useState } from 'react';
import WidePage from '../../Layouts/WidePage';
import ResultPager from '../../Components/SearchForm/ResultPager';
import SearchForm from '../../Components/SearchForm';
import SearchResults from './SearchResults';
import { sessionUserContext } from '../../Context/sessionUserContext';
import { diamondSearchContext } from '../../Context/diamondSearchContext';

function DiamondSearch(){
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ perPageCount, setPerPageCount ] = useState(100);
  const [ pagerMaxPage, setPagerMaxPage ] = useState(1);

  function setPagerMaxPageAndResetPageNumber(newPagerMaxPage) {
    if (newPagerMaxPage !== pagerMaxPage) {
      setPagerMaxPage(newPagerMaxPage);
      setPageNumber(1);
    }
  }

  return (
    <WidePage title="Diamond Search">
      <diamondSearchContext.Consumer>
        {({searchParams, setSearchParamsPage}) =>
          <sessionUserContext.Consumer>
            {({userAccount, session}) =>
              <>
                <SearchForm
                  extendedSearchOptions
                  searchParams={searchParams}
                  setSearchParams={
                    (params) => setSearchParamsPage(params, '/diamond-search')
                  }
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  perPageCount={perPageCount}
                  setPerPageCount={setPerPageCount}
                  pagerMaxPage={pagerMaxPage}
                  withPricing={userAccount !== null}
                />
                { pagerMaxPage > 1 &&
                  <ResultPager
                    perPageCount={perPageCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pagerMaxPage={pagerMaxPage}
                  />
                }
                <SearchResults
                  withCheckbox={userAccount !== null}
                  withPricing={userAccount !== null}
                  searchParams={searchParams}
                  pageNumber={pageNumber}
                  perPageCount={perPageCount}
                  setPagerMaxPage={setPagerMaxPageAndResetPageNumber}
                />
                { pagerMaxPage > 1 &&
                  <ResultPager
                    perPageCount={perPageCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pagerMaxPage={pagerMaxPage}
                  />
                }
              </>
            }
          </sessionUserContext.Consumer>
        }
      </diamondSearchContext.Consumer>
    </WidePage>
  );
}

export default DiamondSearch;
