import React, {useEffect, useState} from 'react';
import MyOrderResultList from './MyOrderResultList';
import Spinner from 'react-bootstrap/Spinner';
import { apiFetch } from '../../../Util';

function OrderListingLoad(props){
  const {orderType} = props;
  const [userOrders, setUserOrders] = useState(null);

  useEffect(() => {
    const params = {
      order_type: orderType,
    };
    apiFetch.index(['user_order'], params).then((result) => {
      setUserOrders(result.index);
    });
  }, [orderType]);

  if(userOrders === null){
    return <Spinner />;
  }

  return (
    <MyOrderResultList
      orderType={orderType}
      userOrders={userOrders}
    />
  );
}

export default OrderListingLoad;
