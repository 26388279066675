/**
 * Function to convert snake case to camel case.
 */
function snakeToCamelCase(nameString){
  let inflectedString = '';
  let nextPartIndex = 0;
  for(
    let underscoreIndex = nameString.indexOf('_');
    underscoreIndex !== -1;
    underscoreIndex = nameString.indexOf('_', underscoreIndex + 1)
  ){
    inflectedString +=
      nameString.substring(nextPartIndex, underscoreIndex) +
      nameString[underscoreIndex + 1].toUpperCase();
    nextPartIndex = underscoreIndex + 2;
  }
  inflectedString += nameString.substring(nextPartIndex);
  return inflectedString;
}

export default snakeToCamelCase;
