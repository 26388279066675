import React from 'react';
import Button from 'react-bootstrap/Button';

function OrderDiamondRow(props){
  const { enableUpdate, orderDiamond, setOrderDiamondStatus, showStatus } = props;

  return (
    <tr
      className="result-table-row"
      title={`ID: ${orderDiamond.id}`}
    >
      { showStatus &&
        <td>
        {orderDiamond.diamondRecordStatus === 'deleted' &&
          <div
            title="This item is marked as deleted in the web listing because it was not in the latest data feed file."
          >
            <strong>DELETED</strong>
          </div>
        }
        {orderDiamond.diamondRecordStatus === 'active' &&
          <div
            title="This item is marked as active because it appeared in the most recent data feed file."
          >
            <strong>ACTIVE</strong>
          </div>
        }
        {orderDiamond.diamondStatus &&
          <div
            title="This status value is reported by the data feed."
          >
            Status: <strong>{orderDiamond.diamondStatus}</strong>
          </div>
        }
        </td>
      }
      <td>
        <div title={`ID: ${orderDiamond.diamondId}`}>
          <strong>{orderDiamond.stockIdNumber}</strong><br/>
          <span>{orderDiamond.weight} ct.&nbsp;</span>
          <span>{orderDiamond.shape}&nbsp;</span>
          <span>{orderDiamond.clarity}&nbsp;</span>
          {
            orderDiamond.fancy ?
            <span>fancy {orderDiamond.fancyColor}&nbsp;</span> :
            <span>{orderDiamond.color}&nbsp;</span>
          }
          {orderDiamond.labGrown && <span>LAB GROWN&nbsp;</span>}
        </div>
      </td>
      <td>
        {orderDiamond.status === 'pending' && enableUpdate &&
          <>
            <Button
              onClick={() => setOrderDiamondStatus(orderDiamond, 'accepted')}
              variant="success"
              disabled={!enableUpdate}
              className="p-2 m-1"
            >
              Approve
            </Button>
            <Button
              onClick={() => setOrderDiamondStatus(orderDiamond, 'rejected')}
              variant="danger"
              disabled={!enableUpdate}
              className="p-2 m-1"
            >
              Reject
            </Button>
          </>
        }
        {orderDiamond.status === 'pending' && !enableUpdate &&
          <Button
            className="p-2"
            variant="outline-info"
            disabled={!enableUpdate}
          >
            Pending
          </Button>
        }
        {orderDiamond.status !== 'pending' &&
          <>
            {orderDiamond.status === 'accepted' &&
              <Button
                className="p-2"
                onClick={() => setOrderDiamondStatus(orderDiamond, 'pending')}
                variant="outline-success"
                disabled={!enableUpdate}
              >
                Approved
              </Button>
            }
            {orderDiamond.status === 'rejected' &&
              <Button
                className="p-2"
                onClick={() => setOrderDiamondStatus(orderDiamond, 'pending')}
                variant="outline-danger"
                disabled={!enableUpdate}
              >
                Rejected
              </Button>
            }
            { enableUpdate &&
              <Button
                onClick={() => setOrderDiamondStatus(orderDiamond, 'pending')}
                type="button"
                className="close"
                aria-label="Close"
                variant="link"
              >
                <span aria-hidden="true">&times;</span>
              </Button>
            }
          </>
        }
      </td>
    </tr>
  );
}

export default OrderDiamondRow;
