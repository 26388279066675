import React from 'react';
import WidePage from '../../Layouts/WidePage';
import ResultDetail from '../../Components/ResultDetail';
import { sessionUserContext } from '../../Context/sessionUserContext';

function DiamondDetail(props){
  const { match } = props;
  const diamondId = match.params.id;
  return (
    <WidePage title="Diamond Details">
      <sessionUserContext.Consumer>
        {({userAccount, session}) =>
          <div className="shadow bg-white p-3 small">
            <ResultDetail
              diamondId={diamondId}
              withPricing={userAccount !== null}
            />
          </div>
        }
      </sessionUserContext.Consumer>
    </WidePage>
  );
}

export default DiamondDetail;
