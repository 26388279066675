import React from 'react';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';

function LogoLink(props){
  let { height } = props;
  const { href, img } = props;
  if(!height){
    height = 30;
  }
  return (
    <Col className="p-2 xs-12 lg-2 text-center">
      <a href={href}>
        <Image height={height} className="m-2 text-center"  src={`/logos/${img}`} alt="Logo"/>
      </a>
    </Col>
  );
}

export default LogoLink;
