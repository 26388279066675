import React from 'react';
import Image from 'react-bootstrap/Image';

const TOOLTIP = 'This diamond description includes images.'

function PictureIcon(){
  return <Image
    className="row-icon camera-icon"
    src="/img/camera-icon-256x256.png"
    title={TOOLTIP}
    alt="Camera icon" />
}

export default PictureIcon;
