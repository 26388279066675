import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import AccountInfoRow from './AccountInfoRow';

function AccountInfoList(props){
  const { accounts } = props;

  if(accounts.length === 0){
    return <Alert variant="primary">No account registrations to display</Alert>;
  }

  return (
    <div>
      <Alert variant="primary">{accounts.length} account registrations</Alert>
      <Table striped bordered hover size="sm" className="small border-0">
        <thead>
          <tr>
            <th className="p-1">Email</th>
            <th className="p-1">Company&nbsp;Name</th>
            <th className="p-1">Name</th>
            <th className="p-1">Phone</th>
            <th className="p-1">Registration&nbsp;Created</th>
            <th className="p-1">Has&nbsp;User&nbsp;Account</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => <AccountInfoRow account={account} key={account.id} />)}
        </tbody>
      </Table>
    </div>
  );
}

export default AccountInfoList;
