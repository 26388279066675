import React from 'react';
import NavBarLink from '../NavBarLink';

function AnonUserLinks(){
  return (
    <>
      <NavBarLink to="/login">Sign In</NavBarLink>
      <NavBarLink to="/register">Register</NavBarLink>
    </>
  );
}

export default AnonUserLinks;
