import React, { useState, useEffect } from 'react';
import CreateUserButton from './CreateUserButton';
import RejectButton from './RejectButton';
import AttachmentList from './AttachmentList';
import Spinner from 'react-bootstrap/Spinner';
import { apiFetch } from '../../../../../../Util';

function AccountInfo(props){
  const { account } = props;
  const [loading, setLoading] = useState(false);
  const [accountDetail, setAccountDetail] = useState(null);
  const na = <em>n/a</em>

  useEffect(() => {
    setLoading(true);
    apiFetch.read(['account_info'], account.id).then((result) => {
      setLoading(false);
      setAccountDetail(result.read);
    });
  }, [account]);

  if(loading || accountDetail === null){
    return <Spinner />
  }

  return (
    <div>
      <div>
        <span className="pr-1">
          <CreateUserButton
            disabled={accountDetail.userAccountId || accountDetail.recordStatus === 'approved'}
            account={accountDetail}
          />
        </span>
        <span className="pr-1">
          <RejectButton
            disabled={accountDetail.userAccountId || accountDetail.recordStatus === 'rejected'}
            account={accountDetail}
          />
        </span>
      </div>
      <p className="large">
        <dt>Full Name</dt>
        <dd>{accountDetail.fullName}</dd>
        <dt>Email</dt>
        <dd><a href={`mailto:${accountDetail.emailAddress}`}>{accountDetail.emailAddress}</a></dd>
        <dt>Company Name</dt>
        <dd>{accountDetail.companyName || na}</dd>
        <dt>Mailing Address</dt>
        <dd>{accountDetail.mailingAddress || na}</dd>
        <dt>Phone Number</dt>
        <dd>{accountDetail.phoneNumber || na}</dd>
        <dt>Business References</dt>
        <dd>{accountDetail.businessReferences || na}</dd>
        <dt>JBT Number</dt>
        <dd>{accountDetail.jbtNumber || na}</dd>
        <dt>Tax ID / Retailer Certification Number</dt>
        <dd>{accountDetail.businessIdNumber || na}</dd>
        <dt>Years In Business</dt>
        <dd>{accountDetail.yearsInBusiness || na}</dd>
        <dt>File Attachments</dt>
        <AttachmentList fileList={accountDetail.fileList} />
      </p>
      <p className="small">
        ID {accountDetail.id} |
        Created {accountDetail.createdTimestamp} |
        Updated {accountDetail.updatedTimestamp} |
        UserAccountId {accountDetail.userAccountId || na}
      </p>
    </div>
  );
}

export default AccountInfo;
