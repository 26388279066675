import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FormAlert from '../FormAlert';
import {apiFetch} from '../../Util';
import LabelRequired from '../LabelRequired';
import { Redirect } from 'react-router-dom';

const { Control, Group } = Form;

const INITIAL_MESSAGE = 'Enter a stock number to lookup';
const DONE_MESSAGE = "OK!";
const INITIAL_STATUS = {
  alert: 'primary',
  message: INITIAL_MESSAGE
};
const INITIAL_INPUT = {};

function StockNumberLookupModal(props){
  const { show, onHide } = props;

  const [input, setInput] = useState(INITIAL_INPUT);
  const [validated, setValidated] = useState(false);
  const [lookupResult, setLookupResult] = useState(false);
  const [status, setStatus] = useState(INITIAL_STATUS);

  function resetLookupResult() {
    setLookupResult(false);
  }

  let disabled = false;

  async function handleSubmit(event){
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      disabled = true;
      const withPricing = false; // TODO
      const stockIdNumberInput = input['stock_id_number'];
      const stockIdNumberList = stockIdNumberInput.split(/[\n\t ,]+/);
      if (stockIdNumberList.length === 0) {
        setStatus({
          alert: 'warning',
          message: 'Please enter one or more stock numbers to search for.'
        });
      }
      else if (stockIdNumberList.length === 1) {
        const stockIdNumber = stockIdNumberList[0];
        apiFetch
          .read(['diamond-by-stock-number'], stockIdNumber, { with_pricing: withPricing })
          .then((result) => {
            setLookupResult({
              detailUrl: '/diamond-detail/' + result.read.id
            });
            setStatus({
              alert: 'success',
              message: DONE_MESSAGE
            });
          })
          .catch((e) => {
            if(e.httpStatus === 404){
              setStatus({
                alert: 'warning',
                message: `Not found: no matching record for "${stockIdNumber}"`
              });
            }
            else {
              setStatus({
                alert: 'danger',
                message: `A server error occurred: ${e}`
              });
            }
            resetLookupResult();
          })
          .finally(() => {
            disabled = false;
          });
      }
      else {
        const stockIdNumberParam = stockIdNumberList.join(',');
        apiFetch
          .index(['diamond-by-stock-number'], { with_pricing: withPricing, stock_number_list: stockIdNumberParam })
          .then((result) => {
            const foundStockIdList = result.index.map((item) => item.stockIdNumber);
            const missingStockIdList = result.summary.stockNumberNotFound;
            if (missingStockIdList.length > 0) {
              alert(`Unable to find stock ID numbers: ${missingStockIdList.join(', ')}`);
            }
            if (foundStockIdList.length > 0) {
              setLookupResult({
                detailUrl: '/diamond-stock-number-list/' + foundStockIdList.join(',')
              });
            }
            setStatus({
              alert: 'success',
              message: DONE_MESSAGE
            });
          })
          .catch((e) => {
            setStatus({
              alert: 'danger',
              message: `A server error occurred: ${e}`
            });
            resetLookupResult();
          })
          .finally(() => {
            disabled = false;
          });
      }
    }
    else {
      setStatus({
        alert: 'warning',
        message: 'Please complete all required fields before submitting the form'
      });
    }
    setValidated(true);
  }

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  if (lookupResult) {
    const redirectUrl = lookupResult.detailUrl;
    onHide();
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Stock Number Lookup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAlert variant={status.alert}>
            {status.message}
          </FormAlert>
          <Group>
            <LabelRequired>Stock No.</LabelRequired>
            <Control
              as="textarea"
              rows="3"
              onChange={handleInputChange}
              name="stock_id_number"
              disabled={disabled}
            />
          </Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={disabled}>
            Search
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default StockNumberLookupModal;
