import React, {useState} from 'react';
import ResultDetail from '../../../../../Components/ResultDetail';
import LockedIcon from './LockedIcon';
import PictureIcon from './PictureIcon';
import VideoIcon from './VideoIcon';
import classnames from 'classnames';
import { priceFormat } from '../../../../../Util';
import Form from 'react-bootstrap/Form';

const { Check } = Form;

function ResultTableRow(props){
  const { resultRow, withPricing, withCheckbox, onCheckboxChange, isSelected } = props;
  const [detailOpen, setDetailOpen] = useState(false);

  function closeDetail() {
    setDetailOpen(false);
  }

  function rowClickHandler(e){
    if (e.target.tagName !== 'INPUT') {
      setDetailOpen(!detailOpen);
    }
  }

  function rowCheckboxHandler(e){
    if (onCheckboxChange) {
      onCheckboxChange(e.currentTarget.checked);
    }
  }

  const rowTooltip = '';
  const isFancy = resultRow['fancy'];
  const allColSpan = withCheckbox ? 21 : 20;
  return (
    <>
      <tr
        title={rowTooltip}
        className={classnames(
          "result-table-row",
          detailOpen ? "result-table-row-opened" : "result-table-row-closed",
          isSelected ? "result-table-row-selected" : null,
        )}
        onClick={rowClickHandler}
      >
        { withCheckbox &&
          <td align="center">
            <Check
              aria-label={`Select ${resultRow['stockIdNumber']}`}
              checked={isSelected}
              onChange={rowCheckboxHandler}
              style={{transform: 'scale(1.8)'}}
            />
          </td>
        }
        <td>{resultRow['stockIdNumber']}</td>
        <td>{resultRow['shape']}</td>
        <td>{resultRow['weight']}</td>
        <td>{isFancy ? resultRow['fancyColor'] : resultRow['color']}</td>
        <td>{resultRow['clarity']}</td>
        <td>{resultRow['lab']}</td>
        <td>{resultRow['cutGrade']}</td>
        <td>{resultRow['polish']}</td>
        <td>{resultRow['symmetry']}</td>
        <td>{resultRow['fluorescenceIntensity']}</td>
        <td>{parseFloat(resultRow['depthPercent']).toFixed(1)}%</td>
        <td>{parseFloat(resultRow['tablePercent']).toFixed(1)}%</td>
        <td>{resultRow['length']}x{resultRow['width']}x{resultRow['depth']}</td>
        <td>{parseFloat(resultRow['ratio']).toFixed(2)}:1</td>
        { withPricing &&
          <>
            <td align="right">${priceFormat(resultRow['ctPrice'])}</td>
            <td align="right">${priceFormat(resultRow['price'])}</td>
            <td align="right">
              {
                resultRow['rappaportDiscountPercent'] ?
                parseFloat(resultRow['rappaportDiscountPercent']).toFixed(1) + '%' :
                'n/a'
              }
            </td>
            <td>{resultRow['status']}</td>
          </>
        }
        { !withPricing &&
          <>
            <td align="center"><LockedIcon /></td>
            <td align="center"><LockedIcon /></td>
            <td align="center"><LockedIcon /></td>
            <td align="center"><LockedIcon /></td>
          </>
        }
        <td className="center text-center">{resultRow['imageUrlList'] ? <PictureIcon /> : null }</td>
        <td className="center text-center">{resultRow['videoUrlList'] ? <VideoIcon /> : null }</td>
      </tr>
      { detailOpen &&
        <>
          <tr className="result-table-row-detail">
            <td colSpan={allColSpan} className="shadow">
              <div>
                <button
                  aria-label="Close"
                  className="close"
                  onClick={closeDetail}
                  type="button"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ResultDetail
                diamondId={resultRow['id']}
                withPricing={withPricing}
              />
            </td>
          </tr>
          <tr className="result-table-row-detail-margin">
            <td colSpan={allColSpan} className="border-0 p-1 small">
              &nbsp;
            </td>
          </tr>
        </>
      }
    </>
  );
}

export default ResultTableRow;
