import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import React, { useEffect } from 'react';

function CardPage(props){
  const { children, title } = props;

  useEffect(() => {
    document.title = title;
  });

  return (
    <Container>
      <Row className="my-5 layouts-cardpage-content">
        <Col lg={1} xs={0}/>
        <Col lg={10} xs={12}>
          <h2 className="font-brand">{title}</h2>
          <Card>
            <Card.Body>
              {children}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={1} xs={0}/>
      </Row>
    </Container>
  );
}

export default CardPage;
