import React, { useState } from 'react';
import classnames from 'classnames';
import OrderDetail from '../../../../../Components/OrderDetail';
import OrderMessagesWorkflow from '../../../../../Components/OrderMessagesWorkflow';

function ResultRow(props){
  const { onOrderUpdated, userOrder } = props;

  const [detailOpen, setDetailOpen] = useState(false);

  function rowClickHandler(id){
    setDetailOpen(!detailOpen);
  }

  const openClassName =
    detailOpen ? "result-table-row-opened" : "result-table-row-closed";

  return (
    <>
      <tr
        className={classnames("result-table-row", openClassName)}
        title={`ID: ${userOrder.id}`}
        key={userOrder.id}
        onClick={rowClickHandler}
      >
        <td>
          {userOrder.orderNumber}
        </td>
        <td>
          <span title={`ID: ${userOrder.id}`}>
            {userOrder.createdTimestamp}
          </span>
        </td>
        <td>
          {userOrder.diamondCount}
        </td>
        <td>
          {userOrder.emailAddress}
        </td>
      </tr>
      { detailOpen &&
        <>
          <tr className="result-table-row-detail">
            <td colSpan="4" className="shadow">
              <OrderDetail
                adminRequest={true}
                enableUpdate={true}
                showStatus={true}
                onOrderUpdated={onOrderUpdated}
                userOrder={userOrder}
              />
              <OrderMessagesWorkflow
                admin={true}
                userOrderId={userOrder.id}
              />
            </td>
          </tr>
          <tr className="result-table-row-detail-margin">
            <td colSpan="3" className="border-0 p-1 small">
              &nbsp;
            </td>
          </tr>
        </>
      }
    </>
  );
}

export default ResultRow;
