import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { GlassMagnifier } from "react-image-magnifiers";

const imageMatch = /\.(jpg|jpeg|gif|png)$/;
const iframeMatch = /\.(html|htm)$/;

function ResultImageDisplay(props){
  const { label, url, magnifierGlass, enlargeToFile } = props;
  const [showModal, setShowModal] = useState(false);

  function handleClose(){
    setShowModal(false);
  }
  function handleThumbnailImageClick(){
    setShowModal(true);
  }
  function handleImageClick(){
    window.open(url);
  }

  const handleEnlarge = enlargeToFile ? handleImageClick : handleThumbnailImageClick;

  if(url === null || url === false || url === '' || url === undefined){
    return null;
  }

  if(url.match(iframeMatch)){
    return (
      <>
        <div className="diamond-detail-img-container px-1 mx-1">
          <div className="border">
            <iframe
              src={url}
              title={label}
              className="diamond-detail-iframe"
            />
          </div>
          <div onClick={handleEnlarge} className="diamond-detail-iframe-enlarge">
            enlarge
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={handleClose}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>{label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src={url}
              title={label}
              className="diamond-detail-iframe-enlarged border"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleImageClick}>
              View Full Screen
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  if(url.match(imageMatch)){
    return (
      <>
        <div className="diamond-detail-img-container">
          <img
            alt={label}
            className="diamond-detail-img border"
            onClick={handleEnlarge}
            src={url}
          />
          {
            // <GlassMagnifier
            //   className="diamond-detail-img border"
            //   imageSrc={url}
            //   largeImageSrc={url}
            //   allowOverflow={true}
            //   magnifierSize={'80%'}
            //   magnifierBorderSize={5}
            //   magnifierBorderColor={'white'}
            //   title={label}
            //   alt={label}
            //   square={false}
            //   onClick={handleThumbnailImageClick}
            // />
          }
          <div onClick={handleEnlarge} className="diamond-detail-iframe-enlarge">
            enlarge
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={handleClose}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>{label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { ! magnifierGlass &&
              <img
                alt={label}
                className="diamond-detail-img-modal"
                src={url}
              />
            }
            { magnifierGlass &&
              <GlassMagnifier
                className="diamond-detail-img-modal"
                imageSrc={url}
                largeImageSrc={url}
                allowOverflow={true}
                magnifierSize={'80%'}
                magnifierBorderSize={5}
                magnifierBorderColor={'white'}
                title={label}
                alt={label}
                square={false}
                onClick={handleThumbnailImageClick}
              />
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleImageClick}>
              View Full Screen
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  /* Catch all... */
  return (
    <div className="diamond-detail-img-container">
      <div className="diamond-detail-attachment-link border text-justify">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="diamond-detail-attachment-content"
        >
          View Video Attachment
        </a>
      </div>
    </div>
  );
}

export default ResultImageDisplay;
