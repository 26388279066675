import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from "react-router-dom";

function AdminPageLayout(props){
  const {children, title} = props;
  return (
      <Container>
        <Row className="my-5 layouts-cardpage-content">
          <Col xs={12}>
            <h3>{title}</h3>
            <Nav defaultActiveKey="/home" as="ul">
              <Nav.Item as="li">
                <NavLink className="nav-link" to="/admin/content">Content</NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink className="nav-link" to="/admin/account-info">Registrations</NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink className="nav-link" to="/admin/user-account">User Accounts</NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink className="nav-link" to="/admin/user-order">Orders</NavLink>
              </Nav.Item>
            </Nav>
            <Card>
              <Card.Body>
                {children}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
}

export default AdminPageLayout;
