import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { apiFetch } from '../../../../../../../Util';

function RejectButton(props){
  const {account, disabled} = props;
  const [loading, setLoading] = useState(false);

  function createUser(){
    setLoading(true);
    apiFetch
      .update(['account_info'], account.id, {'record_status': 'rejected'});
  }

  return (
    <Button
      disabled={disabled || loading}
      onClick={createUser}
      variant="danger"
    >
      Reject Application
      { loading && <Spinner /> }
    </Button>
  );
}

export default RejectButton;
