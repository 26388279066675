import React, {useState} from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormAlert from '../FormAlert';
import {apiFetch} from '../../Util';

const { Control, Group } = Form;

const INITIAL_MESSAGE = 'Set new password';
const DONE_MESSAGE = "Password updated";

function SetPasswordForm(props){
  const {user} = props;
  const [input, setInput] = useState({});
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState({
    alert: 'primary',
    message: INITIAL_MESSAGE
  });

  let disabled = false;

  async function handleSubmit(event){
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      try {
        disabled = true;
        if(input['password_1'] !== input['password_2']){
          setStatus({
            alert: 'danger',
            message: "Passwords do not match"
          });
          return;
        } else {
          const requestData = {
            // user_name: user.userName,
            // email_address: user.emailAddress,
            // groups: user.groups,
            new_password: input['password_1']
          };
          await apiFetch.update(['user_account'], user.id, requestData);
          setStatus({
            alert: 'success',
            message: DONE_MESSAGE
          });
        }
        disabled = false;
      } catch (e) {
        setStatus({
          alert: 'danger',
          message: `A server error occurred while updating password: ${e}`
        });
        disabled = false;
      }
    }
    else {
      setStatus({
        alert: 'warning',
        message: 'Please complete all required fields before submitting the form'
      });
    }
    setValidated(true);
  }

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  return (
    <>
      <FormAlert variant={status.alert}>
        {status.message}
      </FormAlert>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Group>
              <Control
                placeholder="Password"
                type="password"
                onChange={handleInputChange}
                name="password_1"
                disabled={disabled}
                autoComplete="off"
                required
              />
            </Group>
          </Col>
          <Col>
            <Group>
              <Control
                placeholder="Confirm"
                type="password"
                onChange={handleInputChange}
                name="password_2"
                disabled={disabled}
                autoComplete="off"
                required
              />
            </Group>
          </Col>
          <Col>
            <Button
              variant="primary"
              type="submit"
              disabled={disabled}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SetPasswordForm;
