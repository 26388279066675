import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { apiFetch } from '../../../Util';

function SessionTokenRedirect(props){
  const {logInSession, token} = props;
  const [loggingIn, setLoggingIn] = useState(true);
  useEffect(() => {
    const [sessionId] = token.split('/');
    apiFetch.setAuthToken(token);
    apiFetch.read(['session'], sessionId)
      .then((response) => {
        const sessionRecord = response.read;
        sessionRecord['token'] = token;
        logInSession(sessionRecord);
        setLoggingIn(false);
      });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps
  if(loggingIn){
    return null;
  }
  return (
    <Redirect to="/my-account" />
  );
}

export default SessionTokenRedirect;
