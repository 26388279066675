import React from 'react';

const cartContext = React.createContext({
  cartItems: initialCartContext(),
  setCartItems: () => { throw Error('Cart context not initialized.'); },
  addItem: () => { throw Error('Cart context not initialized.'); },
  removeItem: () => { throw Error('Cart context not initialized.'); },
  emptyItems: () => { throw Error('Cart context not initialized.'); },
});

function initialCartContext(){
  return {};
}

export { cartContext, initialCartContext };
