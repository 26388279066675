import React, {useState, useEffect, useRef} from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import ResultDetailRow from './ResultDetailRow';
import ResultImageDisplay from './ResultImageDisplay';
import ResultImageDisplayList from './ResultImageDisplayList';
import { baseUrl } from '../../Util/constants';
import { sessionUserContext } from '../../Context/sessionUserContext';

import { apiFetch, priceFormat } from '../../Util';
import {
  fluorescenceValueLabels,
  shapeValueLabels
} from '../../Util/constants';


function ResultDetail(props){
  const { diamondId, withPricing } = props;
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    const requestParams = { with_pricing: withPricing };
    apiFetch.read(['diamond'], diamondId, requestParams).then((result) => {
      setDetail(result.read);
    });
  }, [withPricing, diamondId]);

  const infoTableEl = useRef(null);
  const linkUrlTextEl = useRef(null);

  if(detail === null){
    return (
      <Spinner animation="grow" size="lg" />
    );
  }

  const diamondUrl = `${baseUrl}/diamond-detail/${detail['id']}`;
  const shape = shapeValueLabels[detail['shape']];
  const diamondTitle = `${detail['weight']}-Carat ${shape} ${detail['stockIdNumber']}`;

  async function emailMe(){
    return apiFetch.create(['diamond_detail_email'], {'diamond_id_list': [detail['id']]})
      .then((response) => {
        // console.log('response', response);
        alert(`Email sent to '${response.created.emailRecipientList}'`);
        // {
        //   // DEBUGGING
        //   const el = document.createElement('div');
        //   el.innerHTML = response.created.emailBody;
        //   el.style.border = "10px solid red";
        //   document.body.append(el);
        // }
      })
      .catch((error) => {
        // console.log('error', error)
        alert('Error creating email: ' + error);
      });
  }

  function urlEmail(){
    const enc = window.encodeURIComponent;
    const recipient = '';
    const body =
      diamondTitle +
      "\n\n" +
      infoTableEl.current.innerText +
      "\n\n" +
      `Visit ${diamondUrl} for more info and images`;
    const subject =
      `Diamond Listing at Diagem Inc. - ${diamondTitle}`;
    const mailToUrl =
      `mailto:${enc(recipient)}?subject=${enc(subject)}&body=${enc(body)}`;
    window.open(mailToUrl, '_blank','noopener noreferrer');
  }

  function copyUrl(){
    const el = linkUrlTextEl.current;
    el.select();
    el.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
  }

  return (
    <>
      <h4>
        {detail['stockIdNumber']}
      </h4>
      <p className="small">
        {detail['weight']} Carat {shapeValueLabels[detail['shape']]}
      </p>
      <Row>
        <Col xs={8} lg={4}>
          <Table
            ref={infoTableEl}
            striped={false}
            className="table-reset table-sm border border-light"
          >
            <tbody>
              <ResultDetailRow
                label="Shape"
                value={detail['shape']}
                annotation={shapeValueLabels[detail['shape']]}
              />
              <ResultDetailRow label="Weight" value={detail['weight']} unit='ct.' />
              <ResultDetailRow label="Color" value={detail['color']} />
              <ResultDetailRow label="Clarity" value={detail['clarity']} />
              <ResultDetailRow label="Depth" value={detail['depthPercent']} />
              <ResultDetailRow label="Table" value={detail['tablePercent']} />
              <ResultDetailRow label="Cut" value={detail['cutGrade']} />
              <ResultDetailRow label="Polish" value={detail['polish']} />
              <ResultDetailRow label="Symmetry" value={detail['symmetry']} />
              <ResultDetailRow
                label="Fluorescence"
                value={detail['fluorescenceIntensity']}
                annotation={fluorescenceValueLabels[detail['fluorescenceIntensity']]}
              />
              <ResultDetailRow
                label="Fluorescence Color"
                value={detail['fluorescenceColor']}
              />
              <ResultDetailRow
                label="Measurements"
                value={`${detail['length']}x${detail['width']}x${detail['depth']}`}
              />
              <ResultDetailRow label="Ratio" value={parseFloat(detail['ratio']).toFixed(2)} />
              <ResultDetailRow label="Lab" value={detail['lab']} />

              <ResultDetailRow label="Crown Angle" value={detail['crownAngle']} />
              <ResultDetailRow label="Crown Height" value={detail['crownHeight']} />
              <ResultDetailRow label="Culet" value={detail['culetSize']} />
              <ResultDetailRow label="Eye Clean" value={detail['eyeClean']} />
              <ResultDetailRow label="Fancy Color" value={detail['fancyColor']} />
              <ResultDetailRow label="Fancy Color Intensity" value={detail['fancyColorIntensity']} />
              <ResultDetailRow label="Fancy Color Overtone" value={detail['fancyColorOvertone']} />
              <ResultDetailRow label="Girdle %" value={detail['girdlePercent']} />
              <ResultDetailRow label="Girdle Thick" value={detail['girdleThick']} />
              <ResultDetailRow label="Girdle Thin" value={detail['girdleThin']} />
              <ResultDetailRow label="Inscription" value={detail['laserInscription']} />
              <ResultDetailRow label="Pavilion Angle" value={detail['pavilionAngle']} />
              <ResultDetailRow label="Pavilion Depth" value={detail['pavilionDepth']} />
              <ResultDetailRow label="Shade" value={detail['shade']} />
              <ResultDetailRow label="Stock ID Number" value={detail['stockIdNumber']} />
              <ResultDetailRow label="Status" value={detail['status']} />
              <ResultDetailRow label="Trade Show" value={detail['tradeShow']} />
              <ResultDetailRow label="Treatment" value={detail['treatment']} />
              { withPricing &&
                <>
                  <ResultDetailRow label="Price per Ct." value={priceFormat(detail['ctPrice'])} unit="$" prefixUnit />
                  <ResultDetailRow label="Price" value={priceFormat(detail['price'])} unit="$" prefixUnit />
                </>
              }
              <ResultDetailRow label="Comments" value={detail['comments']} />
            </tbody>
          </Table>
        </Col>
        <Col xs={4} lg={8}>
          <div className="my-3">
            <Button id="send-email" onClick={urlEmail}>
              Send Email
            </Button>
            <label className="mx-1" htmlFor="send-email">
              Send an email with details about this stone using your
              normal mail app
            </label>
          </div>

          <sessionUserContext.Consumer>
            {({session, userAccount, initialized}) => {
              if (initialized && session && userAccount) {
                return (
                  <div className="my-3">
                    <Button id="send-email" onClick={emailMe}>
                      Email Report
                    </Button>
                    <label className="mx-1" htmlFor="send-email">
                      Send an email to yourself with details about this stone
                    </label>
                  </div>
                );
              }
              return null;
            }}
          </sessionUserContext.Consumer>

          <div className="my-3">
            <label htmlFor="copy-url">Use this link to share this stone with others</label>
            <InputGroup>
              <InputGroup.Append>
                <Button onClick={copyUrl} variant="primary">
                  Copy to Clipboard
                </Button>
              </InputGroup.Append>
              <Form.Control
                type="text"
                id="copy-url"
                value={diamondUrl}
                ref={linkUrlTextEl}
                aria-describedby="inputGroupAppend"
                readOnly
              />
            </InputGroup>
          </div>
          <div className="d-flex flex-wrap flex-row justify-content-around align-items-stretch p-3 m-3">
            <ResultImageDisplay
              label="Certificate"
              url={detail['certificateImageUrl']}
              magnifierGlass={false} enlargeToFile={true}
            />
            <ResultImageDisplayList
              label="Image"
              urlList={detail['imageUrlList']}
              magnifierGlass={true}
            />
            <ResultImageDisplayList
              label="Video"
              urlList={detail['videoUrlList']}
              magnifierGlass={true}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ResultDetail;
