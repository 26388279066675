import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { sessionUserContext } from '../../Context/sessionUserContext';

function IfLoggedOut(props){
  const { children } = props;
  return (
    <sessionUserContext.Consumer>
      {({session, userAccount, initialized}) => {
        if(initialized){
          if(session && userAccount){
            return null;
          }
          else{
            return children;
          }
        }
        else {
          return <Spinner animation="grow" size="sm" />;
        }
      }}
    </sessionUserContext.Consumer>
  );
}

export default IfLoggedOut;
