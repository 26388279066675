import React from 'react';
import Button from 'react-bootstrap/Button';

function UserAccount(props){
  const { user } = props;
  return (
    <div>
      <Button href={`/admin/user-edit/${user.id}`}>Edit...</Button>
      <dt>userName</dt> <dd>{user.userName}</dd>
      <dt>groups</dt> <dd>{user.groups.join(', ')}</dd>
      <dt>created</dt> <dd>{user.createdTimestamp}</dd>
      <dt>updated</dt> <dd>{user.updatedTimestamp}</dd>
      <dt>id</dt> <dd>{user.id}</dd>
    </div>
  );
}

export default UserAccount;
