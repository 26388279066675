import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import LabelRequired from '../../../../Components/LabelRequired';
import FormAlert from '../../../../Components/FormAlert';
import {apiFetch} from '../../../../Util';

const { Control, Group } = Form;

const INITIAL_MESSAGE = 'Edit User Account';
const DONE_MESSAGE = "User account updated";

function UserAccountEditForm(props){
  const {user, setUser} = props;
  const [input, setInput] = useState({...user});
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState({
    alert: 'primary',
    message: INITIAL_MESSAGE
  });

  let disabled = false;

  async function handleSubmit(event){
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      try {
        disabled = true;
        const updateResponse = await apiFetch.update(['user_account'], user.id, input);
        setUser(updateResponse.updated);
        setStatus({
          alert: 'success',
          message: DONE_MESSAGE
        });
        disabled = false;
      } catch (e) {
        setStatus({
          alert: 'danger',
          message: `A server error occurred while updating this account: ${e}`
        });
        disabled = false;
      }
    }
    else {
      setStatus({
        alert: 'warning',
        message: 'Please complete all required fields before submitting the form'
      });
    }
    setValidated(true);
  }

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  function handleCheckboxChange(e){
    let listValue = null;
    if(e.currentTarget.checked){
      listValue = [...input[e.currentTarget.name], e.currentTarget.value];
    } else {
      listValue = input[e.currentTarget.name].filter((i) => i !== e.currentTarget.value);
    }
    setInput({
      ...input,
      [e.currentTarget.name]: listValue
    });
  }

  return (
    <>
      <FormAlert variant={status.alert}>
        {status.message}
      </FormAlert>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Group>
          <LabelRequired>User Name</LabelRequired>
          <Control
            type="text"
            onChange={handleInputChange}
            name="user_name"
            disabled={disabled}
            autoComplete="off"
            defaultValue={input.userName}
            required
          />
        </Group>
        <Group>
          <LabelRequired>Email Address</LabelRequired>
          <Control
            type="email"
            onChange={handleInputChange}
            name="email_address"
            disabled={disabled}
            autoComplete="off"
            defaultValue={input.emailAddress}
            required
          />
        </Group>
        <Group>
          <FormCheck.Label>Groups</FormCheck.Label>
          <Form.Check type="checkbox">
            <Form.Check.Input
              onChange={handleCheckboxChange}
              name="groups"
              type="checkbox"
              value="login"
              disabled={disabled}
              checked={input.groups.includes('login')}
            />
            <Form.Check.Label>login</Form.Check.Label>
          </Form.Check>
          <Form.Check type="checkbox">
            <Form.Check.Input
              onChange={handleCheckboxChange}
              name="groups"
              type="checkbox"
              value="admin"
              disabled={disabled}
              checked={input.groups.includes('admin')}
            />
            <Form.Check.Label>admin</Form.Check.Label>
          </Form.Check>
          <Form.Check type="checkbox">
            <Form.Check.Input
              onChange={handleCheckboxChange}
              name="groups"
              type="checkbox"
              value="disabled"
              disabled={disabled}
              checked={input.groups.includes('disabled')}
            />
            <Form.Check.Label>disabled</Form.Check.Label>
          </Form.Check>
        </Group>
        <Button
          variant="primary"
          type="submit"
          disabled={disabled}
        >
          Save
        </Button>
      </Form>
    </>
  );
}

export default UserAccountEditForm;
