import React, {useState} from 'react';
import AdminPage from '../../Layouts/AdminPage';
import UserAccountBrowser from './UserAccountBrowser';
import IfLoggedIn from '../../Components/IfLoggedIn';
import FormCheck from 'react-bootstrap/FormCheck';
import Form from 'react-bootstrap/Form';

function AdminUserAccount(){
  const [showDisabled, setShowDisabled] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  return (
    <AdminPage title="User Accounts">
      <IfLoggedIn>
        <div class="container p-3">
          <div class="row">
            <div class="col-6">
                <Form.Control as="select" onChange={(e) => {
                  setShowAdmin(e.currentTarget.value === 'true')
                }} custom>
                  <option value="false">Client Accounts</option>
                  <option value="true">Administrator Accounts</option>
                </Form.Control>
            </div>
            <div class="col-6">
              <div className="py-2">
                <Form.Check type="checkbox">
                  <Form.Check.Input
                    onChange={(e) => {
                      setShowDisabled(e.currentTarget.checked)
                    }}
                    name="showDisabled"
                    type="checkbox"
                    value="true"
                    checked={showDisabled}
                  />
                  <Form.Check.Label>show disabled accounts</Form.Check.Label>
                </Form.Check>
              </div>
            </div>
          </div>
        </div>

        <UserAccountBrowser
          showDisabled={showDisabled}
          showAdmin={showAdmin}
        />
      </IfLoggedIn>
    </AdminPage>
  );
}

export default AdminUserAccount;
