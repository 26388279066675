import React from 'react';
import CardPage from '../../Layouts/CardPage';
import { Redirect, Link } from 'react-router-dom';
import SetPasswordForm from '../../Components/SetPasswordForm';
import IfLoggedIn from '../../Components/IfLoggedIn';
import IfLoggedOut from '../../Components/IfLoggedOut';
import { sessionUserContext } from '../../Context/sessionUserContext';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function MyAccount(){
  return (
    <CardPage title="My Account">
      <IfLoggedIn>
        <sessionUserContext.Consumer>
          {({userAccount}) =>
            <>
              <Row>
                <Col>
                  <dt>User Name</dt>
                  <dd>{userAccount.userName}</dd>
                </Col>
                <Col>
                  <dt>Email</dt>
                  <dd>{userAccount.emailAddress}</dd>
                </Col>
              </Row>
              { userAccount.groups.includes('admin') &&
                <Row>
                  <Col>
                    <Alert variant="warning">
                      <Link to="/admin">&gt; Go to site admin tools</Link>
                    </Alert>
                  </Col>
                </Row>
              }
              <SetPasswordForm user={userAccount}/>
            </>
          }
        </sessionUserContext.Consumer>
      </IfLoggedIn>
      <IfLoggedOut>
        <Redirect to="/login" />
      </IfLoggedOut>
    </CardPage>
  );
}

export default MyAccount;
