
const UPPER_CASE_REGEX = /(.*)([A-Z])(.*)/;

/**
 * Function to convert snake case to camel case.
 */
function camelToSnakeCase(nameString){
  const matches = nameString.match(UPPER_CASE_REGEX);
  if(matches){
    return camelToSnakeCase(matches[1]) + '_' + matches[2].toLowerCase() + matches[3];
  }
  return nameString;
}

export default camelToSnakeCase;
