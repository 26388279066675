import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import StockNumberLookupButton from '../StockNumberLookupButton';

function AppFooter(){
  return (
    <footer className="bg-white border-top border-dark pt-3 pb-1 component-appfooter-footer">
      <Container>
        <Row>
          <Col xs={3}>
          </Col>
          <Col xs={6}>
            <p className="text-muted text-center">
              <small>
                &copy; 2020 Diagem Inc.<br/>
                All rights reserved.
              </small>
            </p>
          </Col>
          <Col xs={3}>
            <StockNumberLookupButton />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default AppFooter;
