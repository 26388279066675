import React from 'react';
import { Switch, Route } from "react-router-dom";

import AboutUs from './Pages/AboutUs';
import AdminHome from './Pages/AdminHome';
import AdminUserAccount from './Pages/AdminUserAccount';
import AdminAccountInfo from './Pages/AdminAccountInfo';
import AdminContent from './Pages/AdminContent';
import AdminUserEdit from './Pages/AdminUserEdit';
import AdminUserOrderIndex from './Pages/AdminUserOrderIndex';
import ContactUs from './Pages/ContactUs';
import DiamondSearch from './Pages/DiamondSearch';
import DiamondStockNumberSearch from './Pages/DiamondStockNumberSearch';
import DiamondDetail from './Pages/DiamondDetail';
import Forms from './Pages/Forms';
import Home from './Pages/Home';
import Jewelry from './Pages/Jewelry';
import Login from './Pages/Login';
import Logout from './Pages/Logout';
import MyAccount from './Pages/MyAccount';
import MyOrder from './Pages/MyOrder';
import MyOrderListing from './Pages/MyOrderListing';
import PasswordReset from './Pages/PasswordReset';
import PageNotFound from './Pages/PageNotFound';
import Register from './Pages/Register';

function AppRoutes(){
  return (
    <Switch>
      <Route path="/about-us" component={AboutUs} />
      <Route path="/admin" exact component={AdminHome} />
      <Route path="/admin/account-info" component={AdminAccountInfo} />
      <Route path="/admin/content" component={AdminContent} />
      <Route path="/admin/user-account" component={AdminUserAccount} />
      <Route path="/admin/user-edit/:id" component={AdminUserEdit} />
      <Route path="/admin/user-order/:orderType?" component={AdminUserOrderIndex} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/diamond-detail/:id" component={DiamondDetail} />
      <Route path="/diamond-search" component={DiamondSearch} />
      <Route path="/diamond-stock-number-list/:idList" component={DiamondStockNumberSearch} />
      <Route path="/forms" component={Forms} />
      <Route path="/jewelry" component={Jewelry} />
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <Route path="/my-account" component={MyAccount} />
      <Route path="/my-orders/:orderType?" component={MyOrderListing} />
      <Route path="/my-order/:id" component={MyOrder} />
      <Route path="/password-reset/:token?" component={PasswordReset} />
      <Route path="/register" component={Register} />
      <Route exact path="/" component={Home} />
      <Route path="/api" onEnter={() => window.location.reload()} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}

export default AppRoutes;
