import React from 'react';
import AdminPage from '../../Layouts/AdminPage';

function AdminHome(){
  return (
    <AdminPage title="Admin Tools">
      <ul>
        <li><a href="/admin/content">Edit Content</a></li>
        <li><a href="/admin/account-info">Approve New Registrations</a></li>
        <li><a href="/admin/user-account">Manage User Accounts</a></li>
        <li><a href="/admin/user-order">Manage User Orders</a></li>
      </ul>
    </AdminPage>
  );
}

export default AdminHome;
