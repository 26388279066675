import React, {useState} from 'react';
import OrderMessageSend from '../OrderMessageSend';
import OrderDetailMessages from '../OrderDetailMessages';

function OrderMessagesWorkflow(props){
  const {admin, userOrderId} = props;

  const [lastSentMessageId, setLastSentMessageId] = useState(null);

  function newMessageHandler(newMessage){
    setLastSentMessageId(newMessage.id);
    return true; // allowAnother
  }

  return (
    <>
      <OrderDetailMessages
        admin={admin}
        userOrderId={userOrderId}
        lastSentMessageId={lastSentMessageId}
      />
      <OrderMessageSend
        admin={admin}
        userOrderId={userOrderId}
        newMessageCallback={newMessageHandler}
      />
    </>
  );
}

export default OrderMessagesWorkflow;
