import React, {useState} from 'react';
import CardPage from '../../Layouts/CardPage';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Redirect } from 'react-router-dom';
import IfLoggedIn from '../../Components/IfLoggedIn';
import IfLoggedOut from '../../Components/IfLoggedOut';
import OrderListingLoad from './OrderListingLoad';

function MyOrderListing(props){
  const { match } = props;
  const { params } = match;
  const [orderType, setOrderType] = useState(params.orderType || 'response_sent');

  return (
    <CardPage title="Orders">
      <IfLoggedIn>
        <Tabs
          id="order-controlled-tabs"
          activeKey={orderType}
          onSelect={(k) => setOrderType(k)}
        >
          <Tab eventKey="response_sent" title="Responses">
            <OrderListingLoad orderType={orderType} />
          </Tab>
          <Tab eventKey="request" title="Pending Orders">
            <OrderListingLoad orderType={orderType} />
          </Tab>
        </Tabs>
      </IfLoggedIn>
      <IfLoggedOut>
        <Redirect to="/login" />
      </IfLoggedOut>
    </CardPage>
  );
}

export default MyOrderListing;
