import React, { useEffect } from 'react';
import AdminPageLayout from './AdminPageLayout';
import Spinner from 'react-bootstrap/Spinner';
import { sessionUserContext } from '../../Context/sessionUserContext';
import { Redirect } from 'react-router-dom';

function AdminPage(props){
  const { children, title } = props;

  useEffect(() => {
    document.title = title;
  });

  return (
    <sessionUserContext.Consumer>
      {({initialized, session, userAccount}) => {
        if(initialized){
          if(userAccount === null){
            return <Redirect to="/login" />;
          }
          else if(userAccount.groups.includes('admin')){
            return <AdminPageLayout title={title}>{children}</AdminPageLayout>;
          } else {
            return <Redirect to="/" />;
          }
        } else {
          return <Spinner />;
        }
      }}
    </sessionUserContext.Consumer>
  );
}

export default AdminPage;
