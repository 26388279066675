import React from 'react';
import ContentPage from '../../Layouts/ContentPage';
import ContactInfoRow from '../../Components/ContactInfoRow';
import SiteContentMarkdown from '../../Components/SiteContentMarkdown';
import Image from 'react-bootstrap/Image';

function AboutUs(){
  return (
    <ContentPage title="About Diagem Inc." className="page-about-us">
      <div className="content-wide-img">
        <Image fluid src="/img/chi-city-horiz-2.jpg" className="my-3"/>
      </div>
      <div className="text-justify my-3">
        <SiteContentMarkdown contentId="about_us_content" />
      </div>
      <h3>Contact Us</h3>
      <ContactInfoRow />
      <div className="content-wide-img">
        <Image fluid src="/img/diamond-horiz-1.jpg" className="my-3"/>
      </div>
      <h3 style={{'fontSize': '1.2em'}}><em>Disclaimer</em></h3>
      <p className="small text-justify">
        <em>
          Diagem Inc. procures diamonds from legitimate sources not involved
          in funding conflict and in compliance with United Nations resolutions.
          The seller hereby guarantees that these diamonds all are certified
          to be from sources that are free from conflict, based on personal
          knowledge and/or written guarantees provided by the supplier of these
          diamonds and all are compliance with the Kimberly Process and the
          CDTA (Clean Diamond Trade Act)
        </em>
      </p>
    </ContentPage>
  );
}

export default AboutUs;
