import React, { useState } from 'react';
import { apiFetch } from '../../Util';
// import LabelRequired from '../LabelRequired';
import FormAlert from '../FormAlert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

const { Group, Control } = Form;

const INITIAL_MESSAGE =
  'Send us a message or inquiry relating to this order. We will reply as soon as possible, during business hours.';
const THANK_YOU_MESSAGE =
  "Thank you for your message. We will respond shortly.";

function OrderMessageSend(props) {
  const { admin, userOrderId, newMessageCallback, verboseAlert } = props;
  const [input, setInput] = useState({});
  const [status, setStatus] = useState({
    alert: 'primary',
    message: INITIAL_MESSAGE
  });

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  let disabled = status.alert === 'success';
  let finished = status.alert === 'success';
  let messageEmpty = !(input['message_body'] && input['message_body'].length > 1);

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity()) {
      try {
        const createParams = {...input};
        if (admin) {
          createParams['admin'] = true;
        }
        disabled = true;
        const result = await apiFetch.create(['user_order', userOrderId, 'order_message'], createParams);
        const newOrderMessage = result.created;
        setStatus({
          alert: 'success',
          message: THANK_YOU_MESSAGE
        });
        if(newMessageCallback){
          const allowAnother = newMessageCallback(newOrderMessage);
          if(allowAnother){
            setStatus({
              alert: 'primary',
              message: INITIAL_MESSAGE
            });
          }
        }
      } catch (e) {
        setStatus({
          alert: 'danger',
          message: `A server error occurred while submitting your message: ${e}`
        });
      }
    }
    else {
      setStatus({
        alert: 'warning',
        message: 'Please complete all required fields before submitting the form'
      });
    }
  };

  const emailRecipientLabel = admin ? 'the customer' : 'Diagem Inc. staff';

  return (
    <div>
      { (verboseAlert || status.alert === 'danger' || status.alert === 'warning') &&
        <FormAlert variant={status.alert}>
          {status.message}
        </FormAlert>
      }
      { !finished &&
        <div
          className={classnames(
            'border',
            'rounded-lg',
            'p-2',
            'm-1',

            admin ? 'bg-info' : 'bg-primary',
            admin ? 'border-info' : 'border-primary',
            admin ? 'text-black' : 'text-white',
            admin ? 'mr-3' : 'ml-3',
          )}>
          <Form noValidate onSubmit={handleSubmit}>
            <Group>
              { /* <LabelRequired>Order Message</LabelRequired> */ }
              <Control
                as="textarea"
                rows="3"
                name="message_body"
                placeholder="Enter reply message here"
                onChange={handleInputChange}
                required
                disabled={disabled}
              />
            </Group>
            <Button
              className="mx-1"
              variant={admin ? "primary" : "info"}
              type="submit"
              disabled={disabled || messageEmpty}
            >Reply to Message</Button>
            <span>
              This will post a message here and send an email to
              the {emailRecipientLabel}.
            </span>
          </Form>
        </div>
      }
    </div>
  );
}

export default OrderMessageSend;
