
/* The site base URL, for forming links, should be without slash at end. */
const baseUrl = window.location.host.startsWith('localhost') ?
  `http://localhost:${window.location.port}` :
  `https://${window.location.host}`;

const colorValueLabels = {
  'D': 'D',
  'E': 'E',
  'F': 'F',
  'G': 'G',
  'H': 'H',
  'I': 'I',
  'J': 'J',
  'K': 'K',
  'L': 'L',
  '*OTHER': 'M-Z'
};

const fancyColorValueLabels = {
  'R': 'Red',
  'O': 'Orange',
  'Y': 'Yellow',
  'G': 'Green',
  'B': 'Blue',
  'P': 'Purple',
  'PINK': 'Pink',
  'BN': 'Brown',
  'GR': 'Gray',
  '*OTHER': 'Other'
};

const clarityValueLabels = {
  'FL': 'FL',
  'IF': 'IF',
  'VVS1': 'VVS1',
  'VVS2': 'VVS2',
  'VS1': 'VS1',
  'VS2': 'VS2',
  'SI1': 'SI1',
  'SI2': 'SI2',
  'SI3': 'SI3',
  'I1': 'I1',
  'I2': 'I2',
  'I3': 'I3',
};

const labValueLabels = {
  'GIA': 'GIA',
  'AGS': 'AGS',
  'EGL': 'EGL',
  'IGI': 'IGI',
  'HRD': 'HRD',
  'GCAL': 'GCAL',
  'PGS': 'PGS',
  '*OTHER': 'Other',
  '*NONE': 'None'
};

const shapeValueImagePaths = {
  'BR': '/img/diamond-search/shape-round.png',
  'PR': '/img/diamond-search/shape-princess.png',
  'CU': '/img/diamond-search/shape-cusion.png',
  'OV': '/img/diamond-search/shape-oval.png',
  'PS': '/img/diamond-search/shape-pear.png',
  'MQ': '/img/diamond-search/shape-marquise.png',
  'EC': '/img/diamond-search/shape-emerald.png',
  'SQEM': '/img/diamond-search/shape-asscher.png',
  'RA': '/img/diamond-search/shape-radiant.png',
  // 'SQRA': '/img/diamond-search/shape-square-radiant.png',
  'HS': '/img/diamond-search/shape-heart.png',
  'TRI': '/img/diamond-search/shape-triangle.png'
};

const shapeValueLabels = {
  'BR': 'Round',
  'PR': 'Princess',
  'CU': 'Cushion',
  'OV': 'Oval',
  'PS': 'Pear',
  'MQ': 'Marquise',
  'EC': 'Emerald',
  'SQEM': 'Asscher / Sq. Emerald',
  'RA': 'Radiant',
  // 'SQRA': 'Square Radiant',
  'HS': 'Heart',
  'TRI': 'Triangular',
  '*OTHER': 'Other',
};

const gradeValueLabels = {
  // 'I': 'Ideal',
  'EX': 'Excellent',
  'VG': 'Very Good',
  'G': 'Good',
  'F': 'Fair'
};

const cutValueLabels = gradeValueLabels;
const polishValueLabels = gradeValueLabels;
const symmetryValueLabels = gradeValueLabels;

const fluorescenceValueLabels = {
  'N': 'None',
  'F': 'Faint',
  'M': 'Medium',
  'S': 'Strong',
  'VS': 'Very Strong'
};

export {
  baseUrl,
  clarityValueLabels,
  colorValueLabels,
  cutValueLabels,
  fancyColorValueLabels,
  fluorescenceValueLabels,
  gradeValueLabels,
  labValueLabels,
  polishValueLabels,
  shapeValueLabels,
  shapeValueImagePaths,
  symmetryValueLabels
};
