import React, { useState } from 'react';
import AdminPage from '../../Layouts/AdminPage';
import AccountInfoBrowser from './AccountInfoBrowser';
import IfLoggedIn from '../../Components/IfLoggedIn';
import Form from 'react-bootstrap/Form';


function AdminAccountInfo(){
  const [recordStatus, setRecordStatus] = useState('pending');

  function handleSelectChange(e){
    setRecordStatus(e.currentTarget.value);
  }

  return (
    <AdminPage title="Account Registrations">
      <IfLoggedIn>
        <div className="w-50 m-auto p-3">
          <Form.Control as="select" onChange={handleSelectChange} custom>
            <option value="pending">Pending</option>
            <option value="rejected">Rejected</option>
            <option value="approved">Approved</option>
          </Form.Control>
        </div>
        <AccountInfoBrowser recordStatus={recordStatus}/>
      </IfLoggedIn>
    </AdminPage>
  );
}

export default AdminAccountInfo;
