import classnames from 'classnames';
import React from 'react';
import { diamondSearchContext } from '../../../../../../Context/diamondSearchContext';

function ResultTableHeading(props) {
  const { children, sortField } = props;

  function clickHandler(searchParams, setSearchParamsPage) {
    const sortFieldParam = searchParams.sortField;
    const sortDirectionParam = searchParams.sortDirection;
    if(sortFieldParam === sortField) {
      const newSortDirection = sortDirectionParam === 'desc' ? 'asc' : 'desc';
      const newParams = {...searchParams, sortDirection: newSortDirection};
      setSearchParamsPage(newParams, '/diamond-search')
    } else {
      const newParams = {...searchParams, sortField: sortField, sortDirection: 'asc'};
      setSearchParamsPage(newParams, '/diamond-search')
    }
  }

  function classNames(searchParams) {
    const sortFieldParam = searchParams.sortField;
    const sortDirectionParam = searchParams.sortDirection;
    const classList = ["p-1", "clickable"];
    if (sortFieldParam === sortField) {
      classList.push(`sort-head-${sortDirectionParam}`);
    }
    return classnames(classList);
  }

  return (
    <diamondSearchContext.Consumer>
      {({searchParams, setSearchParamsPage}) =>
        <th
          className={classNames(searchParams)}
          onClick={() => clickHandler(searchParams, setSearchParamsPage)}
        >
          {children}
        </th>
      }
    </diamondSearchContext.Consumer>
  );
}

export default ResultTableHeading;
