import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'core-js/modules/es.object.entries';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.object.values';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.filter';

import './styles.scss';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
