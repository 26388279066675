import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ResetButton from '../ResetButton';
import StockNumberLookupButton from '../../../../Components/StockNumberLookupButton';

function SearchResultButtonHeader(props) {
  const { count, pageNumber, totalCount, perPageCount } = props;
  
  if(count === 0){
    return (
      <Alert variant="warning" className="border-warning text-center">
        No matching results found.
        <ResetButton pagePath="/diamond-search" />
      </Alert>
    );
  }
  
  if(pageNumber > 1) {
    const startingIndex = (pageNumber - 1) * perPageCount;
    let endingIndex = pageNumber * perPageCount;
    if (endingIndex > totalCount) {
      endingIndex = totalCount;
    }
    return (
      <p className="text-center">
        Showing items
        <strong> {startingIndex} </strong>
        to
        <strong> {endingIndex} </strong>
        out of
        <strong> {totalCount} </strong>
        total matches.
        <Button
          className="mx-1"
          variant="success"
        >
          Search
        </Button>
        <ResetButton pagePath="/diamond-search" />
        <StockNumberLookupButton />
      </p>
    );
  }
  return (
    <p className="text-center">
      Showing
      <strong> {count} </strong>
      out of
      <strong> {totalCount} </strong>
      total matches.
      <Button
        className="mx-1"
        variant="success"
      >
        Search
      </Button>
      <ResetButton pagePath="/diamond-search" />
      <StockNumberLookupButton />
    </p>
  );    
}

export default SearchResultButtonHeader;
