import inflectResponse from './inflectResponse';
import snakeToCamelCase from './snakeToCamelCase';
import camelToSnakeCase from './camelToSnakeCase';

class APIConnectionHTTPError{
  constructor(httpStatus, responseJson){
    this.httpStatus = httpStatus;
    this.responseJson = responseJson;
  }

  toString(){
    if(this.responseJson && this.responseJson.message){
      return this.responseJson.message;
    }
    return `Failed with HTTP status "${this.httpStatus}"`;
  }
}

class APIConnection {
  constructor(baseUrl){
    this.authToken = null;
    this.baseUrl = baseUrl;
    this.inflectName = snakeToCamelCase;
    this.uninflectName = camelToSnakeCase;
    this.fetchMode = 'same-origin';
  }

  async index(pathParts, args){
    return this.jsonFetch(
      this.fetchUrl(pathParts, args),
      this.fetchJsonOptions('GET')
    );
  }

  async create(pathParts, data){
    return this.jsonFetch(
      this.fetchUrl(pathParts),
      this.fetchJsonOptions('POST', data)
    );
  }

  async upload(pathParts, id, fileObjList){
    return this.jsonFetch(
      this.fetchUrl([...pathParts, id]),
      this.fetchUploadOptions('POST', fileObjList)
    );
  }

  async read(pathParts, id, args){
    return this.jsonFetch(
      this.fetchUrl([...pathParts, id], args),
      this.fetchJsonOptions('GET')
    );
  }

  async update(pathParts, id, data){
    return this.jsonFetch(
      this.fetchUrl([...pathParts, id]),
      this.fetchJsonOptions('PATCH', data)
    );
  }

  async remove(pathParts, id){
    return this.jsonFetch(
      this.fetchUrl([...pathParts, id]),
      this.fetchJsonOptions('DELETE')
    );
  }

  async jsonFetch(url, options){
    const response = await fetch(url, options);
    const responseJson = await response.json();
    const responseData = this.inflectResponse(responseJson);
    if(response.status !== 200){
      throw new APIConnectionHTTPError(response.status, responseData);
    }
    return responseData;
  }

  inflectResponse(responseJson){
    return inflectResponse(responseJson, this.inflectName);
  }

  inflectParams(responseJson){
    return inflectResponse(responseJson, this.uninflectName);
  }

  setAuthToken(token){
    this.authToken = token;
  }

  clearAuthToken(){
    this.setAuthToken(null);
  }

  authorizationHeader(contentType){
    if(this.authToken){
      return {'Authorization': `Bearer ${this.authToken}`};
    }
    return {};
  }

  fetchUploadOptions(method, fileObjList, formPartName = 'upload'){
    const options = {
      method,
      headers: this.authorizationHeader(),
      mode: this.fetchMode
    };
    const formData = new FormData()
    for(let fileObj of fileObjList){
      formData.append(formPartName, fileObj);
    }
    options.body = formData;
    return options;
  }

  fetchJsonOptions(method, data = null){
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...this.authorizationHeader()
      },
      mode: this.fetchMode
    };
    if(method === 'POST' || method === 'PATCH'){
      options.body = JSON.stringify(data)
    }
    return options;
  }

  fetchUrl(pathParts, args = null){
    function encodeQueryArgs(){
      const queryParts = [];
      for(let fieldName of Object.keys(args)){
        const argValue = args[fieldName];
        if(Array.isArray(argValue)){
          for(let argValueItem of argValue){
            queryParts.push(
              encodeURIComponent(fieldName) + '=' + encodeURIComponent(argValueItem)
            )
          }
        }
        else{
          queryParts.push(
            encodeURIComponent(fieldName) + '=' + encodeURIComponent(argValue)
          )
        }
      }
      return queryParts;
    }

    const path = pathParts.map(encodeURIComponent).join('/');
    let queryArgs = '';
    if(args){
      queryArgs = '?' + encodeQueryArgs().join('&');
    }
    return `${this.baseUrl}/api/${path}${queryArgs}`;
  }
}

export default APIConnection;
