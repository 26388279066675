import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormAlert from '../../../Components/FormAlert';
import {apiFetch} from '../../../Util';
import LabelRequired from '../../../Components/LabelRequired';

const { Control, Group } = Form;

const INITIAL_MESSAGE = 'Enter your email address. A temporary login link will be sent, which you can use to reset your password.';
const DONE_MESSAGE = "Password reset email sent. Please check your inbox for a temporary login link.";

function PasswordResetForm(props){
  const [input, setInput] = useState({});
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState({
    alert: 'primary',
    message: INITIAL_MESSAGE
  });

  let disabled = false;

  async function handleSubmit(event){
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      disabled = true;
      const requestData = {
        email_address: input['email_address']
      };
      apiFetch
        .create(['password_reset'], requestData)
        .then(() => {
          setStatus({
            alert: 'success',
            message: DONE_MESSAGE
          });
        })
        .catch((e) => {
          setStatus({
            alert: 'danger',
            message: `A server error occurred while resetting password: ${e}`
          });
        })
        .finally(() => {
          disabled = false;
        });
    }
    else {
      setStatus({
        alert: 'warning',
        message: 'Please complete all required fields before submitting the form'
      });
    }
    setValidated(true);
  }

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  return (
    <>
      <FormAlert variant={status.alert}>
        {status.message}
      </FormAlert>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Group>
          <LabelRequired>Email Address</LabelRequired>
          <Control
            type="email"
            onChange={handleInputChange}
            name="email_address"
            disabled={disabled}
            autoComplete="email"
            required
          />
        </Group>
        <Button
          variant="primary"
          type="submit"
          disabled={disabled}
        >
          Reset Password
        </Button>
      </Form>
    </>
  );
}

export default PasswordResetForm;
