import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import classnames from 'classnames';

const { Control, Group, Label } = Form;

function RangeInput(props){
  const {
    className, unit,
    lowValue, setLowValue,
    highValue, setHighValue,
    anyMatch, setAnyMatch
  } = props;

  const showAnyMatchSwitch = false;

  const lowValueChange =
    (e) => setLowValue(e.currentTarget.value);
  const highValueChange =
    (e) => setHighValue(e.currentTarget.value);
  const matchAnySwitchChange =
    (e) => setAnyMatch(e.currentTarget.checked);

  useEffect(() => {
    if(!showAnyMatchSwitch && anyMatch){
      setAnyMatch(false);
    }
  }, [ anyMatch ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Group className={classnames('d-flex', 'small', className)}>
      <Label className="mr-2 mt-2 font-italic">from</Label>
      <InputGroup size="sm" className="mb-3" style={{width: "12em"}}>
        <Control
          className="text-right"
          aria-describedby="inputGroup-low"
          type="text"
          name="low"
          disabled={anyMatch}
          onChange={lowValueChange}
          value={lowValue}
        />
        <InputGroup.Append>
          <InputGroup.Text id="inputGroup-low">{unit}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      <Label className="mx-2 mt-2 font-italic">to</Label>
      <InputGroup size="sm" className="mb-3" style={{width: "12em"}}>
        <Control
          className="text-right"
          aria-describedby="inputGroup-high"
          type="text"
          name="low"
          disabled={anyMatch}
          onChange={highValueChange}
          value={highValue}
        />
        <InputGroup.Append>
          <InputGroup.Text id="inputGroup-high">{unit}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      {showAnyMatchSwitch &&
        <Form.Check
          className="mx-2 mt-1"
          type="switch"
          checked={anyMatch}
          label="Match Any"
          id="switch"
          onChange={matchAnySwitchChange}
        />
      }
    </Group>
  );
}

export default RangeInput;
