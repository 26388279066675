import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SearchForm from '../../Components/SearchForm';
import {
  diamondSearchContext, buildDefaultSearchParams
} from '../../Context/diamondSearchContext';

const { Group } = Form;

function ShortSearchForm(props){
  const { history, withPricing } = props;

  function runSearch(){
    history.push('/diamond-search');
  }

  return (
    <diamondSearchContext.Consumer>
      {({searchParams, setSearchParamsPage}) =>
        <>
          <SearchForm
            withPricing={withPricing}
            searchParams={searchParams}
            setSearchParams={
              (params) => setSearchParamsPage(params, '/home')
            }
            pageNumber={1}
            setPageNumber={null}
            perPageCount={100}
            setPerPageCount={null}
            pagerMaxPage={1}
          />
          <Group className="d-flex flex-row justify-content-center">
            <Button
              className="mx-1"
              onClick={runSearch}
              variant="success"
            >
              Search
            </Button>
            <Button
              className="mx-1"
              onClick={
                () => setSearchParamsPage(
                  buildDefaultSearchParams(),
                  '/diamond-search'
                )
              }
              variant="danger">
              Reset
            </Button>
          </Group>
        </>
      }
    </diamondSearchContext.Consumer>
  );
}

export default ShortSearchForm;
