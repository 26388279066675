import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { apiFetch } from '../../../Util';
const { Control, Group, Label, Text } = Form;

function LoginForm(props){
  const [input, setInput] = useState({
    user_name: '', password: ''
  });
  const { logInSession } = props;

  const handleInputChange = (e) => setInput({
    ...input,
    [e.currentTarget.name]: e.currentTarget.value
  });

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const newSessionResult = await apiFetch.create(['session'], input);
      const sessionRecord = newSessionResult.created;
      logInSession(sessionRecord);
    } catch (e) {
      alert('Error: ' + e);
    }
  };

  return (
    <Form>
      <Group>
        <Label>User Name or Email Address</Label>
        <Control
          autoComplete="username"
          type="text"
          onChange={handleInputChange}
          name="user_name"
        />
        <Text>
          Enter your user name or email to log in.
          If you do not have an account, <Link to="/register">you can
          request access</Link>.
        </Text>
      </Group>
      <Group>
        <Label>Password</Label>
        <Control
          type="password"
          onChange={handleInputChange}
          autoComplete="curent-password"
          name="password"
        />
        <Text>
          If you do not remember your password,
          use our <Link to="/password-reset">password reset form</Link>.
        </Text>
      </Group>
      <Button variant="primary" type="submit" onClick={handleSubmit}>Submit</Button>
    </Form>
  );
}

export default LoginForm;
