import Button from 'react-bootstrap/Button';
import React, {useContext, useState} from 'react';
import Table from 'react-bootstrap/Table';
import CallToAction from './CallToAction';
import ResultHeadingRow from './ResultHeadingRow';
import ResultItemsActions from './ResultItemsActions';
import ResultTableRow from './ResultTableRow';
import { cartContext } from '../../../../Context/cartContext';

const cartItemsLimit = 4;

function ResultTable(props){
  const { fancyColor, searchResult, withPricing, withCheckbox } = props;
  const resultRows = searchResult.index;
  const context = useContext(cartContext);
  const {cartItems, setCartItems, addItem, removeItem, emptyItems} = context;
  const cartItemCount = Object.keys(cartItems).length;
  const isEmptyCart = cartItemCount > 0;
  const allSelected = cartItemCount === resultRows.length;
  const [showAllCartItems, setShowAllCartItems] = useState(false);

  function uncheckedItems(){
    return resultRows.filter((item) => !(item.id in cartItems));
  }

  function cartItemsList(){
    /* Show all items in cart if "show" is clicked or all are selected. */
    if (showAllCartItems || allSelected) {
      return Object.values(cartItems);
    }
    return Object.values(cartItems).slice(0, cartItemsLimit);
  }

  function onSelectAll(){
    const newItems = {};
    if(!allSelected){
      /* If fewer than all already selected, select all */
      for(let row of resultRows){
        newItems[row.id] = row;
      }
    }
    setCartItems(newItems);
  }

  function RowElement({resultRow}){
    return (
      <ResultTableRow
        key={resultRow.id}
        onCheckboxChange={(isChecked) => {
          if (isChecked) {
            addItem(resultRow.id, resultRow);
          } else {
            removeItem(resultRow.id);
          }
        }}
        resultRow={resultRow}
        withCheckbox={withCheckbox}
        withPricing={withPricing}
        isSelected={resultRow.id in cartItems}
      />
    );
  }

  return (
    <Table striped bordered hover size="sm" className="small border-0">
      <thead>
        <ResultHeadingRow
          fancyColor={fancyColor}
          withCheckbox={withCheckbox}
          withPricing={withPricing}
          onSelectAll={onSelectAll}
          allSelected={allSelected}
        />
      </thead>
      <tbody className="sticky-top bg-light selected-results">
        {cartItemCount > cartItemsLimit && showAllCartItems &&
          <tr className="result-items-actions-row">
            <td colspan={21}>
              <div>
                <span className="small-button-inline">
                  Showing all {cartItemCount} selected items
                </span>
                <Button
                  className="mx-1"
                  onClick={() => setShowAllCartItems(false)}
                  size="sm"
                  variant="outline-primary"
                >
                  show fewer
                </Button>
              </div>
            </td>
          </tr>
        }
        {cartItemsList().map((resultRow) =>
          <RowElement key={resultRow.id} resultRow={resultRow} />
        )}
        {cartItemCount > cartItemsLimit && !showAllCartItems &&
          <tr className="result-items-actions-row">
            <td colspan={21}>
              <div>
                <span className="small-button-inline">
                  Showing {cartItemsLimit} out of {cartItemCount} selected items
                </span>
                <Button
                  className="mx-1"
                  onClick={() => setShowAllCartItems(true)}
                  size="sm"
                  variant="outline-primary"
                >
                  show all
                </Button>
              </div>
            </td>
          </tr>
        }
      </tbody>
      <tbody>
      {uncheckedItems().map((resultRow) =>
        <RowElement key={resultRow.id} resultRow={resultRow} />
      )}
      {withCheckbox && isEmptyCart &&
        <tr className="result-items-actions-row">
          <td colSpan={21} className="sticky-bottom border border-dark bg-info">
            <ResultItemsActions
              cartItems={cartItems}
              emptyItems={emptyItems}
            />
          </td>
        </tr>
      }
      {withCheckbox && !isEmptyCart &&
        <tr className="result-items-actions-row">
          <td colSpan={21} className="sticky-bottom border border-dark bg-info">
            <CallToAction onSelectAll={onSelectAll}/>
          </td>
        </tr>
      }
      </tbody>
    </Table>
  );
}

export default ResultTable;
