import React from 'react';
import ResultTableHeading from './ResultTableHeading';
import Form from 'react-bootstrap/Form';

const { Check } = Form;

function ResultHeadingRow(props){
  const {
    fancyColor, withCheckbox, withPricing,
    onSelectAll, allSelected
  } = props;

  return (
    <tr>
      { withCheckbox &&
        <th align="center">
          <Check
            className="text-center"
            aria-label={`Select All`}
            checked={allSelected}
            onChange={onSelectAll}
            style={{transform: 'scale(1.8)'}}
          />
        </th>
      }
      <ResultTableHeading sortField={'stock_id_number'}>Stock No.</ResultTableHeading>
      <ResultTableHeading sortField={'shape'}>Shape</ResultTableHeading>
      <ResultTableHeading sortField={'weight'}>Ct.</ResultTableHeading>
      <ResultTableHeading sortField={fancyColor ? 'fancy_color' : 'color'}>Color</ResultTableHeading>
      <ResultTableHeading sortField={'clarity'}>Clarity</ResultTableHeading>
      <ResultTableHeading sortField={'lab'}>Lab</ResultTableHeading>
      <ResultTableHeading sortField={'cut_grade'}>Cut</ResultTableHeading>
      <ResultTableHeading sortField={'polish'}>Polish</ResultTableHeading>
      <ResultTableHeading sortField={'symmetry'}>Symmetry</ResultTableHeading>
      <ResultTableHeading sortField={'fluorescence_intensity'}>Flo.</ResultTableHeading>
      <ResultTableHeading sortField={'depth_percent'}>Depth&nbsp;%</ResultTableHeading>
      <ResultTableHeading sortField={'table_percent'}>Table&nbsp;%</ResultTableHeading>
      <th className="p-1">Measurements</th>
      <ResultTableHeading sortField={'ratio'}>Ratio</ResultTableHeading>
      { withPricing && (
        <>
          <ResultTableHeading sortField={'ct_price'}>Price / Ct.</ResultTableHeading>
          <ResultTableHeading sortField={'price'}>Total Price</ResultTableHeading>
          <ResultTableHeading sortField={'rappaport_discount_percent'}>Rap. Disc. %</ResultTableHeading>
          <ResultTableHeading sortField={'status'}>Status</ResultTableHeading>
        </>
      )}
      { !withPricing && (
        <>
          <th className="p-1">Price / Ct.</th>
          <th className="p-1">Total Price</th>
          <th className="p-1">Rap. Disc. %</th>
          <th className="p-1">Status</th>
        </>
      )}
      <ResultTableHeading sortField={'image_url_list'}>Pic.</ResultTableHeading>
      <ResultTableHeading sortField={'video_url_list'}>Vid.</ResultTableHeading>
    </tr>
  );
}

export default ResultHeadingRow;
