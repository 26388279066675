import React from 'react';
import Toast from 'react-bootstrap/Toast';

function AppErrorMessage(props){
  const { errorMessages, setErrorMessages } = props;
  if(errorMessages.length === 0){
    return null;
  }

  function onClose(messageIndex){
    setErrorMessages(errorMessages.filter(
      (_, filterIndex) => messageIndex !== filterIndex
    ));
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      { errorMessages.map((errorMessage, messageIndex) =>
        <Toast className="bg-danger mb-0" onClose={() => onClose(messageIndex)}>
          <Toast.Header>
            <strong className="mr-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>
            <strong className="text-white">{errorMessage}</strong>
          </Toast.Body>
        </Toast>
      )}
    </div>
  );
}

export default AppErrorMessage;
