import React from 'react';

function FormatTime(props){
  function formatTimeString(){
    const { timestamp } = props;
    const nowDate = new Date();
    const timestampDate = new Date(timestamp);
    const secondsSince = (nowDate - timestampDate) / 1000;
    if (secondsSince < 60 * 1.5) {
      return `now`;
    }
    else if (secondsSince < 60 * 60 * 1.5) {
      const minutesAgo = Math.floor(secondsSince / 60);
      return `${minutesAgo} minutes ago`;
    }
    else if (secondsSince < 18 * 60 * 60) {
      const hoursAgo = Math.floor(secondsSince / (60 * 60));
      return `${hoursAgo} hours ago`;
    }
    return `on ${timestampDate.toDateString()} ${timestampDate.toLocaleTimeString()}`;
  }
  return <span className="format-time">{formatTimeString()}</span>;
}

export default FormatTime;
