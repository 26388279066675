import React from 'react';
import Image from 'react-bootstrap/Image';
import ButtonOptions from '../ButtonOptions';
import { shapeValueImagePaths } from '../../../Util/constants';

function ShapeButtonOptions(props){
  const {
    title, valueLabels, selectedValues, selectedButtonVariant, setSelectedValues
  } = props;
  const imgValueLabels = {};
  for(let [value, label] of Object.entries(valueLabels)){
    if(shapeValueImagePaths[value]){
      imgValueLabels[value] =
        <>
          <Image
            className="shape-button-image"
            alt={label}
            title={label}
            src={shapeValueImagePaths[value]}
          />
          <div className="small">{label}</div>
        </>;
    } else {
      imgValueLabels[value] = label;
    }
  }
  return (
    <ButtonOptions
      title={title}
      valueLabels={imgValueLabels}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
      selectedButtonVariant={selectedButtonVariant}
    />
  );
}

export default ShapeButtonOptions;
