import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { apiFetch } from '../../../../../../../Util';
import { useHistory } from "react-router-dom";

function CreateUserButton(props){
  const {account, disabled} = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function createUser(){
    const newUser = {
      user_name: account['emailAddress'],
      email_address: account['emailAddress'],
      groups: ['login'],
      account_info_id: account.id
    };
    setLoading(true);
    apiFetch
      .create(['user_account'], newUser)
      .then((response) => {
        const newUserId = response.created.id;
        history.push(`/admin/user-edit/${newUserId}`);
      });
  }

  return (
    <Button
      disabled={disabled || loading}
      onClick={createUser}
      variant="success"
    >
      Create User
      { loading && <Spinner /> }
    </Button>
  );
}

export default CreateUserButton;
