import React from 'react';
import AdminPage from '../../Layouts/AdminPage';
import ShowContentItems from './ShowContentItems';

function AdminContent(){
  return (
    <AdminPage title="Content">
      <ShowContentItems />
    </AdminPage>
  );
}

export default AdminContent;
