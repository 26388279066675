import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import React, { useEffect } from 'react';

function ContentPage(props){
  const { children, title, className } = props;

  useEffect(() => {
    document.title = title;
  });

  return (
    <Container className={className}>
      <Row className="mt-5 mb-3">
        <Col lg={3} xs={1}/>
        <Col lg={6} xs={10}>
          <h2 className="font-brand">{title}</h2>
        </Col>
        <Col lg={3} xs={1}/>
      </Row>
      <Row className="my-3 layouts-contentpage-content">
        <Col lg={3} xs={1}/>
        <Col lg={6} xs={10}>
          {children}
        </Col>
        <Col lg={3} xs={1}/>
      </Row>
    </Container>
  );
}

export default ContentPage;
