import React from 'react';
import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavBarLink from './NavBarLink';
import { sessionUserContext } from '../../Context/sessionUserContext';
import IfLoggedIn from '../../Components/IfLoggedIn';
import IfLoggedOut from '../../Components/IfLoggedOut';
import UserWelcome from './UserWelcome';
import AnonUserLinks from './AnonUserLinks';

function AppNavBar(){
  return (
    <Navbar bg="white" expand="lg" className="mb-2 shadow component-appnavbar-navbar">
      <Link className="navbar-brand font-brand" to="/">
        <Image src="/logo.png" height="44"/>
        Diagem Inc.
      </Link>
      <Nav className="px-1 text-center">
        <div className="small text-secondary text-center">
          <span className="text-break">ph. <a className="text-secondary" href="tel:312-782-0614">312-782-0614</a></span>
          &nbsp;
          <span className="text-break">fax <a className="text-secondary" href="tel:312-782-4142">312-782-4142</a></span>
          <br/>
          <span className="text-break">email: <a className="text-secondary" href="mailto:Diagem@diageminc.net">Diagem@diageminc.net</a></span>
          <br/>
        </div>
      </Nav>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-light"/>
      <Navbar.Collapse id="basic-navbar-nav" className="flex-grow-1">
        <Nav className="mr-auto">
          <NavBarLink to="/about-us">About Us</NavBarLink>
          <NavBarLink to="/diamond-search">Diamonds</NavBarLink>
          <NavBarLink to="/jewelry">Jewelry</NavBarLink>
          <NavBarLink to="/forms">Forms</NavBarLink>
          <NavBarLink to="/contact-us">Contact Us</NavBarLink>
        </Nav>
      </Navbar.Collapse>
      <Nav>
        <IfLoggedIn>
          <sessionUserContext.Consumer>
            {({userAccount}) =>
              <UserWelcome userAccount={userAccount} />
            }
          </sessionUserContext.Consumer>
        </IfLoggedIn>
        <IfLoggedOut>
          <AnonUserLinks />
        </IfLoggedOut>
      </Nav>
    </Navbar>
  );
}

export default AppNavBar;
