import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const { Control } = Form;

function OrderMessageControl(props) {
  const { value, onChange } = props;
  return (
    <InputGroup size="sm" className="mb-3">
      <Control
        className="text-left"
        rows={3}
        size="sm"
        as="textarea"
        name="message_body"
        value={value}
        onChange={onChange}
      />
    </InputGroup>
  );
}

export default OrderMessageControl;
