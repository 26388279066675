import React, {useEffect, useState} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import UserOrderResultList from './UserOrderResultList';
import { apiFetch } from '../../../Util';

function UserOrderList(props){
  const { orderType } = props;
  const [userOrders, setUserOrders] = useState(null);

  useEffect(() => {
    const params = {
      order_type: orderType,
      admin: true,
    };
    apiFetch
      .index(['user_order'], params)
      .then((result) => setUserOrders(result.index))
      .catch((e) => window.alert("Error loading orders: " + e));
  }, [orderType]);

  function onOrderUpdated(updatedUserOrder){
    if(updatedUserOrder.orderType !== orderType){
      /* Remove from list, if type no longer matches filter. */
      setUserOrders(userOrders.filter(
        (userOrder) => updatedUserOrder.id !== userOrder.id
      ));
    } else {
      /* Otherwise replace. */
      setUserOrders(userOrders.map(
        (userOrder) =>
          updatedUserOrder.id === userOrder.id ?
          updatedUserOrder : userOrder
      ));
    }
  }

  return userOrders === null ?
    <Spinner animation="grow" size="sm" /> :
    <UserOrderResultList
      orderType={orderType}
      userOrders={userOrders}
      onOrderUpdated={onOrderUpdated}
    />;
}

export default UserOrderList;
