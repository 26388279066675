import React, {useEffect, useState} from 'react';
import { apiFetch } from '../../Util';
import OrderDiamondList from './OrderDiamondList';
import OrderWorkflow from './OrderWorkflow';
import Spinner from 'react-bootstrap/Spinner';

function OrderDetail(props){
  const {
    enableUpdate, onOrderUpdated, onMessageSent,
    userOrder, showStatus, adminRequest
  } = props;
  const [orderDiamonds, setOrderDiamonds] = useState(null);

  useEffect(() => {
    const params = adminRequest ? { admin: true,} : {};
    apiFetch
      .index(['user_order', userOrder.id, 'order_diamond'], params)
      .then((result) => setOrderDiamonds(result.index));
  }, [userOrder, adminRequest]);

  function setOrderDiamondStatus(orderDiamond, newStatus) {
    apiFetch
      .update(
        ['user_order', userOrder.id, 'order_diamond'],
        orderDiamond.id,
        { status: newStatus }
      )
      .then((result) => {
        const newOrderDiamonds = [...orderDiamonds];
        const updateOrderDiamond = newOrderDiamonds.find(
          newOrderDiamond => orderDiamond.id === newOrderDiamond.id);
        updateOrderDiamond.status = newStatus;
        setOrderDiamonds(newOrderDiamonds);
      });
  }

  async function onSendResponse(messageParams){
    const requestData = {
      order_type: 'response_sent',
      message_body: messageParams['message_body']
    };

    try {
      const orderResult = await apiFetch.update(['user_order'], userOrder.id, requestData);
      if (onOrderUpdated) {
        onOrderUpdated(orderResult.updated);
      }
      if (onMessageSent) {
        onMessageSent(orderResult.order_message_inserted);
      }
    } catch (e) {
      alert("Error while updating order.");
    }
  }

  if(orderDiamonds === null){
    return <Spinner animation="grow" size="sm" />;
  }

  return (
    <>
      {
        enableUpdate &&
        <OrderWorkflow
          showStatus={showStatus}
          userOrder={userOrder}
          orderDiamonds={orderDiamonds}
          onSendResponse={onSendResponse}
        />
      }
      <OrderDiamondList
        showStatus={showStatus}
        enableUpdate={enableUpdate}
        orderDiamonds={orderDiamonds}
        setOrderDiamondStatus={setOrderDiamondStatus}
      />
    </>
  );
}

export default OrderDetail;
