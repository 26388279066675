import React from 'react';
import {
  clarityValueLabels,
  colorValueLabels,
  cutValueLabels,
  fancyColorValueLabels,
  fluorescenceValueLabels,
  labValueLabels,
  polishValueLabels,
  shapeValueLabels,
  symmetryValueLabels
} from '../Util/constants';

const DEFAULT_WEIGHT_RANGE = ["0.0", "99.0"];
const DEFAULT_PRICE_RANGE = ["0", "99,999,999"];
const DEFAULT_LAB_GROWN = false;
const DEFAULT_FANCY = false;

const diamondSearchContext = React.createContext({
  searchParams: buildDefaultSearchParams(),
  lastSearchPage: null,
  setSearchParamsPage: () => { throw Error('Search not initialized.'); },
});

function buildDefaultSearchParams(){
  const params = {
    clarity: Object.keys(clarityValueLabels),
    color: Object.keys(colorValueLabels),
    cutGrade: Object.keys(cutValueLabels),
    fancy: DEFAULT_FANCY,
    fancyColor: Object.keys(fancyColorValueLabels),
    fluorescenceIntensity: Object.keys(fluorescenceValueLabels),
    lab: Object.keys(labValueLabels),
    polish: Object.keys(polishValueLabels),
    shape: Object.keys(shapeValueLabels),
    symmetry: Object.keys(symmetryValueLabels),
    labGrown: DEFAULT_LAB_GROWN,
    weightRangeMin: DEFAULT_WEIGHT_RANGE[0],
    weightRangeMax: DEFAULT_WEIGHT_RANGE[1],
    weightRangeAny: false,
    priceRangeMin: DEFAULT_PRICE_RANGE[0],
    priceRangeMax: DEFAULT_PRICE_RANGE[1],
    priceRangeAny: false,
    sortField: null,
    sortDirection: 'ASC',
  };
  return params;
}

export { diamondSearchContext, buildDefaultSearchParams };
