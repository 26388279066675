import React from 'react';
import Button from 'react-bootstrap/Button';

import {
  buildDefaultSearchParams, diamondSearchContext
} from '../../../../Context/diamondSearchContext';

function ResetButton(props){
  const { pagePath } = props;
  return (
    <diamondSearchContext.Consumer>
      {({setSearchParamsPage}) =>
        <Button
          className="mx-1"
          onClick={
            () => setSearchParamsPage(
              buildDefaultSearchParams(),
              pagePath
            )
          }
          variant="danger">
          Reset
        </Button>
      }
    </diamondSearchContext.Consumer>
  );
}

export default ResetButton;
