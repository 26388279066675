import React from 'react';
import LogoLink from './LogoLink';
import Row from 'react-bootstrap/Row';

function LogoLinks(){
  return (
    <>
      <Row className="bg-white align-items-center">
        <LogoLink href="https://www.gia.edu/" img="gia-edu.svg" />
        <LogoLink href="https://www.rapnet.com/" img="rapnet-com.svg" height="23" />
        <LogoLink href="https://www.polygon.net/" img="polygon-net.png" />
        <LogoLink href="https://www.igi.org/" img="igi-org.png" />
        <LogoLink href="https://www.americangemsociety.org/page/agslaboratories" img="agslabs.png" />
      </Row>
      <Row className="bg-dark align-items-center">
        <LogoLink href="https://www.idexonline.com/" img="idexonline-com.png" />
        <LogoLink href="https://www.hrdantwerp.com/en" img="hrdantwerp-com.svg" />
      </Row>
    </>
  );
}

export default LogoLinks;
