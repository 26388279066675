import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const { Control } = Form;

function MarkupPercentControl(props) {
  const { value, onChange } = props;
  return (
    <InputGroup size="sm" className="mb-3" style={{width: "12em"}}>
      <Control
        className="text-right"
        aria-describedby="inputGroup-low"
        type="text"
        name="markup_percent"
        value={value}
        onChange={onChange}
      />
      <InputGroup.Append>
        <InputGroup.Text id="inputGroup-low">%</InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
}

export default MarkupPercentControl;
