import React, {useEffect, useState} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import UserAccountList from './UserAccountList';
import { apiFetch } from '../../../Util';

function UserAccountBrowser(props){
  const { showDisabled, showAdmin } = props;
  const [users, setUsers] = useState(null);

  const requestParams = apiFetch.inflectParams({
    showDisabled, showAdmin
  });

  useEffect(() => {
    apiFetch.index(['user_account'], requestParams).then((result) => {
      setUsers(result.index);
    });
  }, [showDisabled, showAdmin]);

  return users === null ?
    <Spinner animation="grow" size="sm" /> :
    <UserAccountList users={users} />;
}

export default UserAccountBrowser;
