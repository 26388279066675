import React from 'react';
import WidePage from '../../Layouts/WidePage';
import StockNumberResults from './StockNumberResults';
import { sessionUserContext } from '../../Context/sessionUserContext';

function DiamondSearch(props){
  const { match } = props;
  const stockIdNumberList = match.params.idList;
  return (
    <WidePage title="Diamond Stock No. Lookup">
      <sessionUserContext.Consumer>
        {({userAccount, session}) =>
          <>
            <StockNumberResults
              withCheckbox={userAccount !== null}
              withPricing={userAccount !== null}
              stockIdNumberList={stockIdNumberList}
            />
          </>
        }
      </sessionUserContext.Consumer>
    </WidePage>
  );
}

export default DiamondSearch;
