import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const { Group, Control } = Form;

function OrderWorkflow(props){
  const { onSendResponse, orderDiamonds, userOrder, showStatus } = props;
  const { orderNumber, orderType, updatedTimestamp } = userOrder;
  const newRequests = orderDiamonds.filter(
    (orderDiamond) => orderDiamond.status === 'pending'
  );
  const newRequestsCount = newRequests.length;
  const [input, setInput] = useState({});

  let disabled = newRequestsCount > 0 ;

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    onSendResponse(input);
  }

  return (
    <Row>
      <Col xs={2}>
        { orderNumber &&
          <h2>Order #{orderNumber}</h2>
        }
        { showStatus &&
          <p>
            This order is a <strong>{orderType}</strong>
          </p>
        }
        { orderType === 'response_sent' &&
          <p>
            This order response was sent on <strong>{updatedTimestamp}</strong>.
          </p>
        }
      </Col>
      <Col xs={10}>
        { showStatus && orderType === 'request' &&
          <Form noValidate onSubmit={handleSubmit}>
            <Group>
              { /* <Label>Message</Label> */ }
              <Control
                as="textarea"
                rows="3"
                name="message_body"
                placeholder="Enter a response message here (optional)"
                onChange={handleInputChange}
                disabled={disabled}
              />
            </Group>
            <Button
              type="submit"
              className="mb-1 mx-1 mt-0"
              disabled={disabled}
            >
              Send Response
            </Button>
            <span>
              This will close the order and send a reply message to the customer.
            </span>
            { newRequestsCount > 0 &&
              <span>
                There are {newRequestsCount} items in this order awaiting approval
              </span>
            }
          </Form>
        }
      </Col>
    </Row>
  );
}

export default OrderWorkflow;
