import React from 'react';
import FormatTime from './FormatTime';
import MessageBody from '../../MessageBody';
import classnames from 'classnames';

function OrderMessageItem(props){
  const { orderMessage } = props;
  const {
    senderIsAdmin, messageBody, senderUserName, senderEmailAddress,
    senderFullName, senderCompanyName, senderPhoneNumber, createdTimestamp
  } = orderMessage;
  const infoSummaryParts = [
    senderUserName + (senderIsAdmin ? '*' : ''),
    (senderUserName !== senderEmailAddress) ? senderEmailAddress : null,
    senderFullName,
    senderCompanyName,
    senderPhoneNumber
  ];
  const sentTimestamp = new Date(createdTimestamp);
  const infoSummary = infoSummaryParts.filter(v => !!v).join(' | ');
  const senderDisplayName = !! senderFullName ?
    `${senderFullName} <${senderEmailAddress}>` :
    senderEmailAddress;
  return (
    <div
      className={classnames(
        'border',
        'rounded',
        'p-2',
        'm-1',
        senderIsAdmin ? 'bg-info' : 'bg-primary',
        senderIsAdmin ? 'border-info' : 'border-primary',
        senderIsAdmin ? 'text-black' : 'text-white',
        senderIsAdmin ? 'mr-3' : 'ml-3',
      )}
    >
      <div title={infoSummary}>
        {senderDisplayName} <em className="text-muted">sent</em> <FormatTime timestamp={sentTimestamp} />
      </div>
      <div className="p-1">
        <MessageBody text={messageBody} />
      </div>
    </div>
  );
}

export default OrderMessageItem;
