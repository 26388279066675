import React from 'react';
import AdminPage from '../../Layouts/AdminPage';
import Spinner from 'react-bootstrap/Spinner';
import UserAccountEdit from './UserAccountEdit';
import { sessionUserContext } from '../../Context/sessionUserContext';

function AdminUserEdit(props){
  const { match } = props;
  const userAccountId = match.params.id;
  return (
    <AdminPage title="Edit User Account">
      <sessionUserContext.Consumer>
        {({session, initialized}) => {
        return initialized ?
          <UserAccountEdit userAccountId={userAccountId} /> :
          <Spinner />
        }}
      </sessionUserContext.Consumer>
    </AdminPage>
  );
}

export default AdminUserEdit;
