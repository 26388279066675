import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import ResultRow from './ResultRow';
import { Link } from "react-router-dom";

function MyOrderResultList(props){
  const { userOrders } = props;

  if(userOrders === null || userOrders.length === 0){
    return (
      <Alert variant="warning" className="my-3">
        No orders of this type to display.
        <br/><br/>
        <Link to="/diamond-search">You can create a new order on our search page.</Link>
      </Alert>
    );
  }

  return (
    <div className="my-3 py-3">
      <Table striped bordered hover size="sm" className="small border-0">
        <thead>
          <tr>
            <th className="p-1">Order #</th>
            <th className="p-1">Created At</th>
            <th className="p-1">Number of Stones</th>
          </tr>
        </thead>
        <tbody>
          {userOrders.map((userOrder) =>
            <ResultRow
              key={userOrder.id}
              userOrder={userOrder}/>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default MyOrderResultList;
