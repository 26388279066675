import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import StockNumberLookupModal from '../StockNumberLookupModal';

function StockNumberLookupButton() {
  const [showLookupModal, setShowLookupModal] = useState(false);
  return (
    <>
      { showLookupModal &&
        <StockNumberLookupModal
          show={true}
          onHide={() => setShowLookupModal(false)}
        />
      }
      <Button
        onClick={() => setShowLookupModal(true)}
        variant="outline-primary"
        disabled={showLookupModal}
        className="p-2 m-1"
      >
        <span role="img" aria-label="search">🔍</span> Stock No. Lookup
      </Button>
    </>
  );
}

export default StockNumberLookupButton;
