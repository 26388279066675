import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';

function FormAlert(props){
  const { children, variant } = props;
  const showWarningBadge = variant === 'warning' || variant === 'danger';

  return (
    <Alert variant={variant} className="mb-3">
      { showWarningBadge &&
        <Badge variant={variant} pill className="alert-badge px-2 py-1 mb-1 mr-2">!</Badge>
      }
      {children}
    </Alert>
  );
}

export default FormAlert;
