import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AppNavBar from './Components/AppNavBar';
import AppFooter from './Components/AppFooter';
import AppErrorMessage from './Components/AppErrorMessage';
import AppRoutes from './AppRoutes';
import SiteNotification from './Components/SiteNotification';
import { diamondSearchContext, buildDefaultSearchParams } from './Context/diamondSearchContext';
import { cartContext, initialCartContext } from './Context/cartContext';
import { contentContext } from './Context/contentContext';
import { sessionUserContext } from './Context/sessionUserContext';
import { apiFetch } from './Util';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function App() {
  const [initialized, setInitialized] = useState(false);
  const [session, setSession] = useState(cookies.get('session'));
  const [content, setContent] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [userAccount, setUserAccount] = useState(null);
  const [searchParams, setSearchParams] = useState(buildDefaultSearchParams());
  const [lastSearchPage, setLastSearchPage] = useState(null);
  const [cartItems, setCartItems] = useState(initialCartContext());

  function setSearchParamsPage(newSearchParams, newLastSearchPage){
    setSearchParams(newSearchParams);
    setLastSearchPage(newLastSearchPage);
  }

  useEffect(() => {
    setInitialized(false);
    if(!session){
      apiFetch.clearAuthToken();
      setUserAccount(null);
      setInitialized(true);
    } else {
      apiFetch.setAuthToken(session.token);
      apiFetch
        .read(['user_account'], session.userAccountId)
        .then((userResult) => {
          setUserAccount(userResult.read);
        })
        .catch((error) => {
          apiFetch.clearAuthToken();
          setUserAccount(null);
        })
        .finally(() => setInitialized(true));
    }
  }, [session]);

  useEffect(() => {
    reloadContent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function logInSession(newSession){
    cookies.set('session', newSession, {
      expires: new Date(newSession.expirationTimestamp)
    });
    setInitialized(false);
    setSession(newSession);
  }

  function logOutSession(){
    cookies.remove('session');
    setSession(null);
  }

  async function reloadContent(){
    const requestParams = {
      identifier_list: ['notice_message', 'notice_color']
    };
    return apiFetch
      .index(['site_content', 'bundle'], requestParams)
      .then((result) => {
        const items = result.index;
        const newContent = {};
        items.forEach(item => newContent[item.identifier] = item.content);
        if(newContent['notice_message'] && newContent['notice_color']){
          setContent(newContent);
        }
      })
      .catch((error) => {
        setErrorMessages([...errorMessages, "Error loading some content on this page."]);
      });
  }

  function emptyItems() {
    setCartItems(initialCartContext());
  }

  function addItem(diamondId, diamondRecord){
    const newCartItems = {
      [diamondId]: diamondRecord,
      ...cartItems
    };
    setCartItems(newCartItems);
  }

  function removeItem(diamondId){
    const newCartItems = {
      ...cartItems
    };
    delete newCartItems[diamondId];
    setCartItems(newCartItems);
  }

  return (
    <Router>
      <sessionUserContext.Provider value={{
        initialized, session, userAccount, logInSession, logOutSession
      }}>
        <diamondSearchContext.Provider value={{
          searchParams, lastSearchPage, setSearchParamsPage
        }}>
          <contentContext.Provider value={{
            content, setContent, reloadContent
          }}>
            <cartContext.Provider value={{
              cartItems, emptyItems, setCartItems, addItem, removeItem
            }}>
              <SiteNotification content={content} />
              <AppNavBar />
              <AppErrorMessage
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
              />
              <AppRoutes />
              <AppFooter />
            </cartContext.Provider>
          </contentContext.Provider>
        </diamondSearchContext.Provider>
      </sessionUserContext.Provider>
    </Router>
  );
}

export default App;
