import React from 'react';
import ButtonOptions from '../ButtonOptions';

function ColorButtonOptions(props){
  const {
    title, valueLabels, selectedValues, selectedButtonVariant, setSelectedValues
  } = props;
  const colorValueLabels = {};
  for(let [value, label] of Object.entries(valueLabels)){
    colorValueLabels[value] =
      <>{label}&nbsp;<span className="button-option-color-label rounded-circle" style={{backgroundColor: label.toLowerCase()}}>&nbsp;&nbsp;&nbsp;</span></>;
  }
  return (
    <ButtonOptions
      title={title}
      valueLabels={colorValueLabels}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
      selectedButtonVariant={selectedButtonVariant}
    />
  );
}

export default ColorButtonOptions;
