import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import UserAccountRow from './UserAccountRow';

function UserAccountList(props){
  const { users } = props;

  if(users.length === 0){
    return <Alert variant="primary">No user accounts to display</Alert>;
  }

  return (
    <div>
      <Alert variant="primary">{users.length} user accounts</Alert>
      <Table striped bordered hover size="sm" className="small border-0">
        <thead>
          <tr>
            <th className="p-1">Email</th>
            <th className="p-1">User&nbsp;Name</th>
            <th className="p-1">Roles</th>
            <th className="p-1">Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => <UserAccountRow user={user} key={user.id}/> )}
        </tbody>
      </Table>
    </div>
  );
}

export default UserAccountList;
