import React, {useEffect, useState} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import AccountInfoList from './AccountInfoList';
import { apiFetch } from '../../../Util';

function AccountInfoBrowser(props){
  const { recordStatus } = props;
  const [accounts, setAccounts] = useState(null);

  useEffect(() => {
    apiFetch
      .index(['account_info'], {record_status: recordStatus})
      .then((result) => setAccounts(result.index));
  }, [recordStatus]);

  return accounts === null ?
    <Spinner animation="grow" size="sm" /> :
    <AccountInfoList accounts={accounts} />;
}

export default AccountInfoBrowser;
