import React, { useEffect, useState } from 'react';
import { apiFetch } from '../../../Util';
import Alert from 'react-bootstrap/Alert';
import ResultTable from '../../DiamondSearch/SearchResults/ResultTable';
import Spinner from 'react-bootstrap/Spinner';

function SearchResults(props){
  const { withCheckbox, withPricing, stockIdNumberList } = props;

  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    const requestParams = apiFetch.inflectParams({
      withPricing, stockNumberList: stockIdNumberList
    });
    apiFetch.index(['diamond-by-stock-number'], requestParams).then((result) => {
      setSearchResult(result);
    });
  }, [withPricing, stockIdNumberList]);

  if(searchResult){
    const itemCount = searchResult.index.length;
    if(itemCount < 1){
      return (
        <Alert variant="warning" className="border-warning text-center">
          No matching stock numbers found.
        </Alert>
      );
    }
    else {
      const fancyColor = 'fancy'; // TODO
      return (
        <>
          <p className="text-center">
            Showing 
            <strong> {itemCount} </strong>
            items
          </p>
          <ResultTable
            fancyColor={fancyColor}
            searchResult={searchResult}
            withCheckbox={withCheckbox}
            withPricing={withPricing}
          />
        </>
      );
    }
  }

  return <Spinner />;
}

export default SearchResults;
