import React, {useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import OrderDetail from '../../../Components/OrderDetail';
import OrderMessagesWorkflow from '../../../Components/OrderMessagesWorkflow';
import Spinner from 'react-bootstrap/Spinner';
import { apiFetch } from '../../../Util';

function OrderDetailLoad(props){
  const { orderId, isAdmin } = props;
  const [userOrder, setUserOrder] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    apiFetch
      .read(['user_order'], orderId, isAdmin ? { admin: true} : {})
      .then((result) => {
        setUserOrder(result.read);
      })
      .catch((error) => {
        setErrorMessage(error.toString())
      });
  }, [isAdmin, orderId]);

  if(errorMessage !== null){
    return (
      <Alert variant="danger">
        Loading Error: {errorMessage}
      </Alert>
    );
  }

  if(userOrder === null){
    return <Spinner />;
  }

  return (
    <div>
      <OrderDetail
        adminRequest={isAdmin}
        enableUpdate={false}
        userOrder={userOrder}
        onOrderUpdated={() => {}}
      />
      <OrderMessagesWorkflow
        admin={isAdmin}
        userOrderId={userOrder.id}
      />
    </div>
  );
}

export default OrderDetailLoad;
