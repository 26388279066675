import React from 'react';

const sessionUserContext = React.createContext({
  session: null,
  userAccount: null,
  initialized: false,
  logInSession: () => { throw Error('Session not initialized.'); },
  logOutSession: () => { throw Error('Session not initialized.'); },
});

export { sessionUserContext };
