import React from 'react';
import { NavLink } from "react-router-dom";

function NavBarLink(props){
  return (
    <NavLink activeClassName="text-dark" className="nav-link" to={props.to}>
      {props.children}
    </NavLink>
  );
}

export default NavBarLink;
