import React from 'react';
import CardPage from '../../Layouts/CardPage';

function MyOrder(props){
  return (
    <CardPage title="404 - Not Found">
      <p>
        <strong>Page Not Found</strong>
      </p>
      <p>
        Please return to our <a href="/">home page</a> or try again.
      </p>
    </CardPage>
  );
}

export default MyOrder;
