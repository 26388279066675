import CardPage from '../../Layouts/CardPage';
import React from 'react';
import { sessionUserContext } from '../../Context/sessionUserContext';
import LogoutForm from './LogoutForm';
import { Redirect } from 'react-router-dom';

function Logout(){
  return (
    <CardPage title="Sign Out">
      <sessionUserContext.Consumer>
        {({logOutSession, session}) => (
          session ?
          <LogoutForm logOutSession={logOutSession} /> :
          <Redirect to="/" />
        )}
      </sessionUserContext.Consumer>
    </CardPage>
  );
}

export default Logout;
