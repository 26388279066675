import React, { useState } from 'react';
import AccountInfo from './AccountInfo';
import classnames from 'classnames';

function AccountInfoRow(props){
  const { account } = props;
  const [detailOpen, setDetailOpen] = useState(false);

  function rowClickHandler(id){
    setDetailOpen(!detailOpen);
  }

  const openClassName =
    detailOpen ? "result-table-row-opened" : "result-table-row-closed";

  return (
    <>
      <tr
        className={classnames("result-table-row", openClassName)}
        title={`ID: ${account.id}`}
        key={account.id}
        onClick={rowClickHandler}
      >
        <td>{account.emailAddress}</td>
        <td>{account.companyName}</td>
        <td>{account.fullName}</td>
        <td>{account.phoneNumber}</td>
        <td>{account.createdTimestamp}</td>
        <td>{account.userAccountId ? 'YES' : 'NO'}</td>
      </tr>
      { detailOpen &&
        <>
          <tr className="result-table-row-detail">
            <td colSpan="6" className="shadow">
              <AccountInfo account={account} />
            </td>
          </tr>
          <tr className="result-table-row-detail-margin">
            <td colSpan="6" className="border-0 p-1 small">
              &nbsp;
            </td>
          </tr>
        </>
      }
    </>
  );
}

export default AccountInfoRow;
