import React from 'react';
import Button from 'react-bootstrap/Button';

function CallToAction(props) {
  const { onSelectAll } = props;
  return (
    <div>
      <strong className="mx-1 lg">⬆</strong> &nbsp;
      <span className="small-button-inline">
        To send an order to Diagem Inc. staff or to create an export,
        use the checkboxes to select diamonds.
        Or you can
      </span>
      <Button
        className="mx-1"
        onClick={onSelectAll}
        size="sm"
        variant="outline-primary"
      >
        select all
      </Button>
    </div>
  );
}

export default CallToAction;
