import APIConnection from './APIConnection';
import { baseUrl } from './constants';

const apiFetch = new APIConnection(baseUrl);

function priceFormat(val){
  return parseInt(val).toLocaleString('en-US');
}

export { apiFetch, priceFormat };
