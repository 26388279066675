import React, {useRef} from 'react';
import Container from 'react-bootstrap/Container';
import LogoLinks from '../../Components/LogoLinks';
import ShortSearchForm from '../../Components/ShortSearchForm';
import SiteContentMarkdown from '../../Components/SiteContentMarkdown';
import { sessionUserContext } from '../../Context/sessionUserContext';

import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';

const SLOW_SPEED = 0.77;
const FAST_SPEED = 1.66;

function Home(props){
  const { history } = props;
  const videoEl = useRef(null);

  function onPlayHandler(e){
    videoEl.current.playbackRate = SLOW_SPEED;
  }

  function onMouseEnterHandler(e){
    videoEl.current.playbackRate = FAST_SPEED;
  }

  function onMouseLeaveHandler(e){
    videoEl.current.playbackRate = SLOW_SPEED;
  }

  return (
    <Container>
      <section className="py-3 my-3 home-top-section">
        <Row>
          <Col xs={1} lg={4} className="home-top-section-left">&nbsp;</Col>
          <Col xs={8} lg={4}>
            <Image src="/img/home-company-name.png" />
          </Col>
          <Col xs={3} lg={4} className="home-top-section-right">&nbsp;</Col>
        </Row>
      </section>

      <Row>
        <Col xs={1} lg={2}>&nbsp;</Col>
        <Col xs={10} lg={8}>
          <Jumbotron className="py-3 my-3">
            <SiteContentMarkdown contentId="home_content" />
          </Jumbotron>
        </Col>
        <Col xs={1} lg={2}>&nbsp;</Col>
      </Row>


      <section
        className="my-3 video-bg rounded border-primary"
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        <div className="overlay"></div>
        <video
          onPlay={onPlayHandler}
          autoPlay
          muted
          loop
          id="home-search-background"
          className="home-search-background"
          ref={videoEl}
        >
          <source src="/img/raining-diamonds-1.mp4" type="video/mp4" />
        </video>
        <div className="container py-3 h-100">
          <h4 className="text-center">Diamond Search</h4>
          <h6 className="text-center">Find your next diamond with us</h6>
          <sessionUserContext.Consumer>
            {({userAccount, session}) =>
              <ShortSearchForm
                history={history}
                withPricing={userAccount !== null}
              />
            }
          </sessionUserContext.Consumer>
        </div>
      </section>

      <section className="py-3 my-3">
        <LogoLinks />
      </section>
    </Container>
  );
}

export default Home;
