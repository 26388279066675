import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { apiFetch } from '../../../../Util';
import { contentContext } from '../../../../Context/contentContext';

function ContentItemForm(props){
  const { contentItemId } = props;
  const [ contentItem, setContentItem ] = useState(null);

  useEffect(() => {
    apiFetch.read(['site_content'], contentItemId).then((result) => {
      setContentItem(result.read);
    });
  }, [contentItemId]);

  function handleChange(e){
    const newContentItem = {
      ...contentItem,
      content: e.currentTarget.value,
    };
    setContentItem(newContentItem);
  }

  async function saveContent(){
    const result = await apiFetch.update(['site_content'], contentItemId, contentItem)
    setContentItem(result.update);
  }

  let field = null;
  if (contentItem === null) {
    field = (
      <div>
        <Spinner animation="grow" size="sm" />Loading...
      </div>
    );
  } else if (contentItem.fieldType.type === 'text') {
    field = (
      <Form.Control
        as="textarea"
        rows="3"
        onChange={handleChange}
        value={contentItem.content}
      />
    );
  } else if (contentItem.fieldType.type === 'markdown') {
    field = (
      <Form.Control
        as="textarea"
        rows="6"
        onChange={handleChange}
        value={contentItem.content}
      />
    );
  } else if (contentItem.fieldType.type === 'select') {
    const { options } = contentItem.fieldType;
    field = (
      <Form.Control onChange={handleChange} as="select" value={contentItem.content} custom>
        {
          Object.entries(options).map(([optionKey, optionValue]) =>
            <option key={optionKey} value={optionKey}>{optionValue}</option>
          )
        }
      </Form.Control>
    );
  } else {
    field = (
      <div>ERROR</div>
    );
  }
  return (
    <contentContext.Consumer>
    {({reloadContent}) => (
      <Form>
        <Form.Group className="d-flex flex-row justify-content-right">
          {field}
          <Button variant="success" onClick={ async () => {
            await saveContent();
            await reloadContent();
          }} className="mx-1">Save</Button>
        </Form.Group>
      </Form>
    )}
    </contentContext.Consumer>
  );
}

export default ContentItemForm;
