import React from 'react';
import Form from 'react-bootstrap/Form';
const { Label } = Form;

function LabelRequired(props){
  const { children } = props;
  return (
    <Label>{children}<sup className="text-info">*</sup></Label>
  );
}

export default LabelRequired;
