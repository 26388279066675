import React from 'react';
import ResultImageDisplay from '../ResultImageDisplay';

function ResultImageDisplayList(props){
  const { label, urlList, magnifierGlass, enlargeToFile } = props;
  if(!Array.isArray(urlList)){
    return null;
  }
  return urlList.map(
    (url, i) =>
      <ResultImageDisplay
        key={`${label}-${i}`}
        label={label}
        magnifierGlass={magnifierGlass}
        enlargeToFile={enlargeToFile}
        url={url}
      />
  );
}

export default ResultImageDisplayList;
