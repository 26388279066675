import React, {useState, useEffect} from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import ContentItemForm from './ContentItemForm';
import { apiFetch } from '../../../Util';

function ShowContentItems(){
  const [contentItems, setContentItems] = useState(null);

  useEffect(() => {
    apiFetch.index(['site_content']).then((result) => {
      setContentItems(result.index);
    });
  }, []);

  if(contentItems === null){
    return <div><Spinner animation="grow" size="sm" />Loading...</div>;
  }
  return (
    <Accordion>
      { contentItems.map((contentItem) =>
        <Card key={contentItem.id}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={contentItem.id}>
              <span title={contentItem.id}>{contentItem.identifier}</span>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={contentItem.id}>
            <Card.Body>
              <ContentItemForm contentItemId={contentItem.id} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
    </Accordion>
  );
}

export default ShowContentItems;
