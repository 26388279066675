import Card from 'react-bootstrap/Card';
import ContentPage from '../../Layouts/ContentPage';
import React from 'react';

import { Link } from "react-router-dom";

function Forms(){
  return (
    <ContentPage title="Forms">
      <Card className="p-3 mb-3">
        <Card.Title>
          Download PDF Forms
        </Card.Title>
        <Card.Body>
          <ul>
            <li>
              <a target="_blank" href="/docs/NewCreditApp_DG.pdf">
                Credit Application
              </a>
            </li>
            <li>
              <a target="_blank" href="/docs/ClientAMLCompliance_DG.pdf">
                USA Patriot Act Client Identification and Verification Form
              </a>
            </li>
            <li>
              <a target="_blank" href="/docs/DIAGEM_COMPLIANCE.pdf">
                Diagem Inc. AML Compliance Disclosure
              </a>
            </li>
          </ul>
        </Card.Body>
      </Card>
      <Card className="p-3">
        <Card.Title>
          Account Registration
        </Card.Title>
        <Card.Body>
          Visit our <Link to="/register">account registration page</Link> to
          sign up for an account on this site.
        </Card.Body>
      </Card>
    </ContentPage>
  );
}

export default Forms;
