import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { apiFetch } from '../../Util';
import ReactMarkdown from 'react-markdown';

function SiteContentMarkdown(props){
  const { contentId } = props;
  const [content, setContent] = useState(null);
  useEffect(() => {
    const requestParams = {
      identifier_list: [contentId]
    };
    apiFetch
      .index(['site_content', 'bundle'], requestParams)
      .then((result) => {
        const items = result.index;
        const newContent = {};
        items.forEach(item => newContent[item.identifier] = item.content);
        setContent(newContent);
      })
  }, [contentId]);

  return content === null ?
    <Spinner /> :
    <ReactMarkdown source={content[contentId]} />;
}

export default SiteContentMarkdown;
