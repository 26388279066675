import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import React from 'react';

const { Group, Text } = Form;

function LogoutForm(props){
  const { logOutSession } = props;
  return (
    <Form>
      <Group>
        <Text className="mb-3">
          You will sign your account out of this browser session if you continue.
        </Text>
        <ButtonToolbar>
          <ButtonGroup>
            <Button variant="primary" type="submit" onClick={logOutSession}>Sign Out Now</Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Group>
    </Form>
  );
}

export default LogoutForm;
