import React from 'react';
import CardPage from '../../Layouts/CardPage';
import OrderDetailLoad from './OrderDetailLoad';
import { Redirect } from 'react-router-dom';
import IfLoggedIn from '../../Components/IfLoggedIn';
import IfLoggedOut from '../../Components/IfLoggedOut';

import Spinner from 'react-bootstrap/Spinner';
import { sessionUserContext } from '../../Context/sessionUserContext';


function MyOrder(props){
  const { match } = props;
  const orderId = match.params.id;
  return (
    <CardPage title="Order">
      <IfLoggedIn>
        <sessionUserContext.Consumer>
          {({session, userAccount, initialized}) => {
            if(initialized){
              const isAdmin = userAccount.groups.includes('admin');
              if(session && userAccount){
                return (
                  <OrderDetailLoad
                    orderId={orderId}
                    isAdmin={isAdmin}
                  />
                );
              }
              else{
                return (
                  <p>Log in to view orders.</p>
                );
              }
            }
            else {
              return (
                <Spinner animation="grow" size="sm" />
              );
            }
          }}
        </sessionUserContext.Consumer>
      </IfLoggedIn>
      <IfLoggedOut>
        <Redirect to="/login" />
      </IfLoggedOut>
    </CardPage>
  );
}

export default MyOrder;
