import React from 'react';
import Image from 'react-bootstrap/Image';

const TOOLTIP = 'This field available to registered users.'

function LockedIcon(){
return <Image
  className="row-icon lock-icon"
  src="/img/lock-icon-256x256.png"
  title={TOOLTIP}
  alt="Lock icon" />
}

export default LockedIcon;
