import React from 'react';
import Alert from 'react-bootstrap/Alert';
import ReactMarkdown from 'react-markdown';

function SiteNotification(props){
  const { content } = props;

  if(content === null || !content['notice_message']){
    return null;
  }

  return (
    <Alert
      className="mb-0 text-center site-content"
      variant={content['notice_color']}
    >
      <ReactMarkdown source={content['notice_message']} />
    </Alert>
  );
}

export default SiteNotification;
