import React from 'react';

function ResultDetailRow(props){
  const {annotation, label, value, unit, prefixUnit } = props;
  const suffixUnit = !prefixUnit;
  if(value === '' || value === null || value === undefined){
    return null;
  }
  return (
    <tr>
      <td className="text-right">
        {label}:
      </td>
      <td className="text-left">
        { prefixUnit && unit && <em>{unit}</em>}
        {value}
        { annotation && <span> - {annotation}</span>}
        { suffixUnit && unit && <em>{unit}</em>}
      </td>
    </tr>
  );
}

export default ResultDetailRow;
