import React, {useEffect, useState} from 'react';
import { apiFetch } from '../../Util';
import FormAlert from '../FormAlert';
import Spinner from 'react-bootstrap/Spinner';
import OrderMessageItem from './OrderMessageItem';

/* Most of the time no status display, only for errors. */
const INITIAL_MESSAGE = null;
const EMPTY_STATUS = {
  alert: 'primary',
  message: INITIAL_MESSAGE
};

function OrderDetailMessages(props){
  const { admin, lastSentMessageId, userOrderId } = props;
  const [orderMessageList, setOrderMessageList] = useState(null);
  const [status, setStatus] = useState(EMPTY_STATUS);

  useEffect(() => {
    if(orderMessageList !== null){
      if(orderMessageList.length === 0){
        if(lastSentMessageId === null){
          return;
        }
      }
      else{
        if(lastSentMessageId === null){
          return;
        }
        else {
          if(orderMessageList[orderMessageList.length - 1].id === lastSentMessageId){
            return;
          }
        }
      }
    }
    apiFetch.index(['user_order', userOrderId, 'order_message'], admin ? {admin: true } : {})
      .then(result => {
        setOrderMessageList(result.index);
        setStatus(EMPTY_STATUS);
      })
      .catch(error => {
        setOrderMessageList([]);
        setStatus({
          alert: 'danger',
          message: `Unable to retrieve order messages from server: '${error}'`,
        });
      });
  }, [admin, lastSentMessageId, orderMessageList, userOrderId]);

  if (orderMessageList === null) {
    return (
      <Spinner />
    );
  }

  return (
    <div>
      { status.message !== null &&
        <FormAlert variant={status.alert}>
          {status.message}
        </FormAlert>
      }
      { orderMessageList.map((orderMessage) => <OrderMessageItem key={orderMessage.id} orderMessage={orderMessage} />)}
    </div>
  );
}

export default OrderDetailMessages;
