import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {apiFetch} from '../../../../../Util';
import MarkupPercentControl from './MarkupPercentControl';
import OrderMessageControl from './OrderMessageControl';
import {sessionUserContext} from '../../../../../Context/sessionUserContext';

import Form from 'react-bootstrap/Form';

const THANK_YOU_MESSAGE = "Thank you for your request. A member of the " +
  "Diagem team will reach out shortly to confirm availability.";

function ToEmailModal(props) {
  const { onCancel, onConfirm, toEmailAddress } = props;
  const [input, setInput] = useState({to_email_address: toEmailAddress});

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  function handleConfirm(){
    onConfirm(input);
  }

  return (
    <Modal.Dialog>
      <Modal.Header onHide={onCancel} closeButton>
        <Modal.Title>Destination Email Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Enter an email address that will receive a report about these stones.</p>
        <Form.Control
          onChange={handleInputChange}
          type="email"
          name="to_email_address"
          placeholder="Enter destination email"
          value={input['to_email_address']}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} variant="secondary">Close</Button>
        <Button onClick={handleConfirm} variant="primary">Send</Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}

function ResultItemsActions(props) {
  const { cartItems, emptyItems } = props;
  const diamondIdList = Object.keys(cartItems);

  const sessionUser = useContext(sessionUserContext);
  const [markupPercent, setMarkupPercent] = useState(0);
  const [messageBody, setMessageBody] = useState('');
  const [toEmailAddress, setToEmailAddress] = useState(sessionUser.userAccount.emailAddress);
  const [showToEmailModal, setShowToEmailModal] = useState(false);

  async function createUserOrder(orderType, markupPercent = 0.0, messageBody = null) {
    const createParams = {
      markup_percent: markupPercent,
      order_type: orderType,
      message_body: messageBody,
    };
    const response = await apiFetch.create(
      ['user_order'],
      createParams
    );
    const newUserOrder = response.created;

    const addParams = {
      diamond_id_list: diamondIdList,
    };
    await apiFetch.create(
      ['user_order', newUserOrder.id, 'order_diamond'],
      addParams
    );

    return newUserOrder;
  }

  async function handleEmailReportWithDestination(modalData) {
    const createParams = {
      markup_percent: markupPercent,
      diamond_id_list: diamondIdList,
      to_email_address: modalData['to_email_address'],
    };
    setShowToEmailModal(false);
    setToEmailAddress(modalData['to_email_address']);
    return apiFetch.create(['diamond_detail_email'], createParams)
      .then((response) => {
        alert(`Email sent to '${response.created.emailRecipientList}'`);
      })
      .catch((error) => {
        alert('Error creating email: ' + error);
      });
  }

  async function handleEmailReport() {
    const createParams = {
      markup_percent: markupPercent,
      diamond_id_list: diamondIdList,
    };
    if(sessionUser.userAccount.groups.includes('admin')){
      setShowToEmailModal(true);
    }
    else {
      return apiFetch.create(['diamond_detail_email'], createParams)
        .then((response) => {
          alert(`Email sent to '${response.created.emailRecipientList}'`);
        })
        .catch((error) => {
          alert('Error creating email: ' + error);
        });
    }
  }

  async function startFileExport (fileType) {
    const newUserOrder = await createUserOrder('export', markupPercent);
    try {
      const url = apiFetch.fetchUrl(
        ['user_order', newUserOrder.id, 'export', fileType])
      const params = `markup_percent=${markupPercent}`;
      const exportUrl = `${url}?${params}`;
      window.open(exportUrl);
      // const options = apiFetch.fetchJsonOptions('GET')
      // const response = await fetch(exportUrl, options);
      // const blob = await response.blob();
      // const downloadURL = URL.createObjectURL(blob);
      // window.open(downloadURL, '_blank');
      // URL.revokeObjectURL(downloadURL);
    } catch (e) {
      alert("Error creating or transferring export file.");
    }
  }

  async function startOrder () {
    await createUserOrder('request', 0.0, messageBody);
    alert(THANK_YOU_MESSAGE);
    emptyItems();
  }

  function markupChanged (e) {
    setMarkupPercent(e.currentTarget.value);
  }

  function messageBodyChanged (e) {
    setMessageBody(e.currentTarget.value);
  }

  function handleDestinationCancel() {
    setShowToEmailModal(false);
  }

  return (
    <div>
      { showToEmailModal &&
        <ToEmailModal
          onCancel={handleDestinationCancel}
          onConfirm={handleEmailReportWithDestination}
          toEmailAddress={toEmailAddress}
        />
      }
      {cartItems &&
        <p className="px-3 mx-3 my-1">
          <span className="small-button-inline">
            <strong>{diamondIdList.length}</strong> selected items
          </span>
          &nbsp;
          <Button
            className="mx-1"
            onClick={emptyItems}
            size="sm"
            variant="outline-primary"
          >
            clear all
          </Button>
        </p>
      }
      <div className="d-inline-flex justify-content-around w-100">
        <div className="border w-50 p-3 m-3">
          <h2>Export</h2>
          Export as<br/>
          <Button onClick={() => startFileExport('pdf')}>PDF</Button>
          &nbsp;
          {
          // <Button onClick={() => startFileExport('csv')}>CSV</Button>
          // &nbsp;
          }
          <Button onClick={() => startFileExport('xls')}>XLS</Button>
          &nbsp;
          <Button onClick={handleEmailReport}>Email Report</Button>
          <br/><br/>
          <strong>Optional: </strong>
          Enter a markup for the prices that appear in the export document
          <MarkupPercentControl value={markupPercent} onChange={markupChanged} />
        </div>
        <div className="border w-50 p-3 m-3">
          <h2>Inquire</h2>
          Inquire as to availability of selected stones
          <br/>
          <Button onClick={startOrder}>Start Inquiry</Button>
          <br/><br/>
          <strong>Order Comments (optional): </strong>
          <OrderMessageControl value={messageBody} onChange={messageBodyChanged} />
        </div>
      </div>
    </div>
  );
}

export default ResultItemsActions;
