import React from 'react';
import NavBarLink from '../NavBarLink';

function UserWelcome(props){
  const { userAccount } = props;
  return (
    <>
      <NavBarLink to="/my-account"><em>{userAccount.userName}</em></NavBarLink>
      <NavBarLink to="/my-orders">View Orders</NavBarLink>
      <NavBarLink to="/logout">Sign Out</NavBarLink>
    </>
  );
}

export default UserWelcome;
