import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

function ResultPager(props){
  const {
    pageNumber, setPageNumber,
    pagerMaxPage
  } = props;
  const pagerNumberItemCount = 8;

  let pagerFirstPage = pageNumber - (pagerNumberItemCount / 2);
  if(pagerFirstPage < 1) {
    pagerFirstPage = 1;
  }
  let pagerLastPage = pagerFirstPage + pagerNumberItemCount;
  if(pagerLastPage > pagerMaxPage){
    pagerLastPage = pagerMaxPage;
  }

  let items = [];
  for (let i = pagerFirstPage; i <= pagerLastPage; i++) {
    items.push(
      <Pagination.Item
        key={i}
        active={i === pageNumber}
        onClick={() => setPageNumber(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Pagination size="sm" className="d-flex justify-content-center">
      { pageNumber > 1 &&
        <Pagination.First
          onClick={() => setPageNumber(1)}
        />
      }
      { pageNumber > 1 &&
        <Pagination.Prev
          onClick={() => setPageNumber(pageNumber - 1)}
        />
      }
      { pagerFirstPage > 1 &&
        <Pagination.Ellipsis />
      }
      { items }
      { pagerLastPage < pagerMaxPage &&
        <Pagination.Ellipsis />
      }
      { pageNumber < pagerMaxPage &&
        <Pagination.Next
          onClick={() => setPageNumber(pageNumber + 1)}
        />
      }
      { pageNumber < pagerMaxPage &&
        <Pagination.Last
          onClick={() => setPageNumber(pagerMaxPage)}
        />
      }
    </Pagination>
  );

}

export default ResultPager;
