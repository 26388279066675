import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classnames from 'classnames';

const { Group, Label } = Form;

function ButtonOptions(props){
  const {
    className,
    selectedValues,
    setSelectedValues,
    title,
    valueLabels,
    selectedButtonVariant = 'dark',
    deselectedButtonVariant = 'light',
  } = props;

  function allSelected(){
    const totalItemCount = Object.keys(valueLabels).length;
    const selectedItemCount = selectedValues.length;
    return selectedItemCount === totalItemCount;
  }

  function getButtonVariant(k){
    return selectedValues.includes(k) ?
      selectedButtonVariant :
      deselectedButtonVariant;
  }

  function clickHandler(value){
    const i = selectedValues.indexOf(value);
    if(i === -1){
      setSelectedValues([...selectedValues, value]);
    }
    else {
      if(allSelected()){
        setSelectedValues([value]);
      }
      else {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.splice(i, 1);
        if(newSelectedValues.length > 0){
          setSelectedValues(newSelectedValues);
        }
        else {
          setSelectedValues(Object.keys(valueLabels));
        }
      }
    }
  }

  return (
    <Row className="my-0">
      <Col xs={2} md={1} className="text-center">
        <Label className="small">{title}</Label>
      </Col>
      <Col xs={10} md={11}>
        <Group className={classnames('overflow-auto', 'd-flex', 'mb-1', className)}>
          { Object.entries(valueLabels).map(([value,label]) =>
            <Button
              size="sm"
              className="flex-fill mr-1 mb-1"
              value={value}
              key={value}
              variant={getButtonVariant(value)}
              onClick={() => clickHandler(value)}
            >
              {label}
            </Button>
          )}
        </Group>
      </Col>
    </Row>
  );
}

export default ButtonOptions;
