import React, { useEffect, useState } from 'react';
import { apiFetch } from '../../../Util';
import ResultTable from './ResultTable';
import SearchResultButtonHeader from './SearchResultButtonHeader';
import Spinner from 'react-bootstrap/Spinner';

import {
  clarityValueLabels,
  colorValueLabels,
  cutValueLabels,
  fancyColorValueLabels,
  fluorescenceValueLabels,
  labValueLabels,
  polishValueLabels,
  symmetryValueLabels
} from '../../../Util/constants';

const BLANK = '*NONE';

function allValuesSelected(optionValues, valueLabels){
  return (
    Array.isArray(optionValues) &&
    optionValues.length >= Object.values(valueLabels).length
  );
}

function SearchResults(props){
  const { withCheckbox, withPricing, searchParams, pageNumber, perPageCount, setPagerMaxPage } = props;

  const [searchResult, setSearchResult] = useState(null);

  function patchedSearchParams(){
    const patchedParams = {...searchParams};

    /* Search for blanks too if all selected */
    if(allValuesSelected(patchedParams['color'], colorValueLabels)){
      patchedParams['color'] = [...patchedParams['color'], BLANK];
    }
    if(allValuesSelected(patchedParams['fancyColor'], fancyColorValueLabels)){
      patchedParams['fancyColor'] = [...patchedParams['fancyColor'], BLANK];
    }
    if(allValuesSelected(patchedParams['clarity'], clarityValueLabels)){
      patchedParams['clarity'] = [...patchedParams['clarity'], BLANK];
    }
    if(allValuesSelected(patchedParams['lab'], labValueLabels)){
      patchedParams['lab'] = [...patchedParams['lab'], BLANK];
    }
    if(allValuesSelected(patchedParams['cutGrade'], cutValueLabels)){
      patchedParams['cutGrade'] = [...patchedParams['cutGrade'], BLANK];
    }
    if(allValuesSelected(patchedParams['polish'], polishValueLabels)){
      patchedParams['polish'] = [...patchedParams['polish'], BLANK];
    }
    if(allValuesSelected(patchedParams['symmetry'], symmetryValueLabels)){
      patchedParams['symmetry'] = [...patchedParams['symmetry'], BLANK];
    }
    if(allValuesSelected(patchedParams['fluorescenceIntensity'], fluorescenceValueLabels)){
      patchedParams['fluorescenceIntensity'] = [...patchedParams['fluorescenceIntensity'], BLANK];
    }

    /* Strip commas or junk from numeric values for large number price range
     * values. */
    if(patchedParams['priceRangeMax'] &&
       typeof patchedParams['priceRangeMax'] == 'string'){
      patchedParams['priceRangeMax'] =
        patchedParams['priceRangeMax'].replace(/[^0-9.]/g, '');
    }
    if(patchedParams['priceRangeMin'] &&
       typeof patchedParams['priceRangeMin'] == 'string'){
      patchedParams['priceRangeMin'] =
        patchedParams['priceRangeMin'].replace(/[^0-9.]/g, '');
    }
    /* Add additional aliased shape values for cusion */
    if(patchedParams['shape'] && patchedParams['shape'].includes('CU')){
      patchedParams['shape'] = [...patchedParams['shape'], 'CMB', 'CB'];
    }
    /* Fluor. aliased values. */
    if(patchedParams['fluorescenceIntensity'] &&
       patchedParams['fluorescenceIntensity'].includes('F')){
      patchedParams['fluorescenceIntensity'] =
        [...patchedParams['fluorescenceIntensity'], 'SL', 'VSL'];
    }
    if(patchedParams['fluorescenceIntensity'] &&
       patchedParams['fluorescenceIntensity'].includes('S')){
      patchedParams['fluorescenceIntensity'] =
        [...patchedParams['fluorescenceIntensity'], 'VS'];
    }
    /* Aliased cut, polish, symm */
    if(patchedParams['cutGrade'] && patchedParams['cutGrade'].includes('EX')){
      patchedParams['cutGrade'] = [...patchedParams['cutGrade'], 'I'];
    }
    if(patchedParams['polish'] && patchedParams['polish'].includes('EX')){
      patchedParams['polish'] = [...patchedParams['polish'], 'I'];
    }
    if(patchedParams['symmetry'] && patchedParams['symmetry'].includes('EX')){
      patchedParams['symmetry'] = [...patchedParams['symmetry'], 'I'];
    }

    return patchedParams;
  }

  useEffect(() => {
    let requestValid = true;
    window.setTimeout(() => {
      const requestParams = apiFetch.inflectParams({
        ...patchedSearchParams(),
        withPricing,
        pageNumber,
        perPageCount
      });
      apiFetch.index(['diamond'], requestParams).then((result) => {
        if(requestValid){
          setSearchResult(result);
          setPagerMaxPage(Math.ceil(result.page.totalCount / perPageCount));
        }
      });
    }, 500);
    return () => {
      requestValid = false;
    };
  }, [withPricing, searchParams, pageNumber, perPageCount]); // eslint-disable-line react-hooks/exhaustive-deps

  if(searchResult){
    return (
      <>
        <SearchResultButtonHeader
          count={searchResult.page.count}
          totalCount={searchResult.page.totalCount}
          pageNumber={searchResult.page.pageNumber}
          perPageCount={searchResult.page.perPageCount}
        />
        { searchResult.page.count >= 1 &&
          <ResultTable
            fancyColor={searchParams['fancy']}
            searchResult={searchResult}
            withCheckbox={withCheckbox}
            withPricing={withPricing}
          />
        }
      </>
    );
  }

  return <Spinner />;
}

export default SearchResults;
