import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import CardPage from '../../Layouts/CardPage';
import FormAlert from '../../Components/FormAlert';
import IfLoggedIn from '../../Components/IfLoggedIn';
import IfLoggedOut from '../../Components/IfLoggedOut';
import LabelRequired from '../../Components/LabelRequired';
import ContactInfoRow from '../../Components/ContactInfoRow';

import { apiFetch } from '../../Util';
import { sessionUserContext } from '../../Context/sessionUserContext';

const { Control, Group } = Form;

const INITIAL_MESSAGE =
  'Send us a message or inquiry. We will reply as soon as possible, during business hours.';
const THANK_YOU_MESSAGE =
  "Thank you for your message. We will respond shortly.";

function ContactUs(){
  const [input, setInput] = useState({});
  const [status, setStatus] = useState({
    alert: 'primary',
    message: INITIAL_MESSAGE
  });
  const [validated, setValidated] = useState(false);

  function handleInputChange(e){
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  let disabled = status.alert === 'success';
  let finished = status.alert === 'success';

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      try {
        disabled = true;
        await apiFetch.create(['message_inquiry'], input);
        setStatus({
          alert: 'success',
          message: THANK_YOU_MESSAGE
        });
      } catch (e) {
        setStatus({
          alert: 'danger',
          message: `A server error occurred while submitting your message: ${e}`
        });
      }
    }
    else {
      setStatus({
        alert: 'warning',
        message: 'Please complete all required fields before submitting the form'
      });
    }
    setValidated(true);
  };

  return (
    <CardPage title="Contact Us">
      <FormAlert variant={status.alert}>
        {status.message}
      </FormAlert>
      { !finished &&
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Group>
            <IfLoggedIn>
              <sessionUserContext.Consumer>
                {({userAccount}) => (
                  <Group>
                    <LabelRequired>From</LabelRequired>
                    <Control
                      type="text"
                      name="email"
                      disabled
                      readOnly
                      defaultValue={`"${userAccount.userName}" <${userAccount.emailAddress}>`}
                    />
                  </Group>
                )}
              </sessionUserContext.Consumer>
            </IfLoggedIn>
            <IfLoggedOut>
              <Group>
                <LabelRequired>Full Name</LabelRequired>
                <Control
                  type="text"
                  name="sender_full_name"
                  onChange={handleInputChange}
                  required
                  autoComplete="name"
                  disabled={disabled}
                />
              </Group>
              <Group>
                <LabelRequired>Email Address</LabelRequired>
                <Control
                  type="email"
                  name="sender_email_address"
                  onChange={handleInputChange}
                  required
                  autoComplete="email"
                  disabled={disabled}
                />
              </Group>
              <Group>
                <LabelRequired>Phone Number</LabelRequired>
                <Control
                  type="tel"
                  name="sender_phone_number"
                  onChange={handleInputChange}
                  required
                  autoComplete="tel"
                  disabled={disabled}
                />
              </Group>
            </IfLoggedOut>
            <Group>
              <LabelRequired>Subject</LabelRequired>
              <Control
                type="text"
                name="message_subject"
                onChange={handleInputChange}
                required
                disabled={disabled}
              />
            </Group>
            <Group>
              <LabelRequired>Message</LabelRequired>
              <Control
                as="textarea"
                rows="3"
                name="message_body"
                onChange={handleInputChange}
                required
                disabled={disabled}
              />
            </Group>
            <Button
              variant="primary"
              type="submit"
              disabled={disabled}
            >Submit</Button>
          </Group>
        </Form>
      }
      <ContactInfoRow />
    </CardPage>
  );
}

export default ContactUs;
