import React, { useState } from 'react';
import UserAccount from '../../../../../Components/UserAccount';
import classnames from 'classnames';

function UserAccountRow(props){
  const { user } = props;
  const [detailOpen, setDetailOpen] = useState(false);

  function rowClickHandler(id){
    setDetailOpen(!detailOpen);
  }

  const openClassName =
    detailOpen ? "result-table-row-opened" : "result-table-row-closed";

  return (
    <>
      <tr
        className={classnames("result-table-row", openClassName)}
        title={`ID: ${user.id}`}
        key={user.id}
        onClick={rowClickHandler}
      >
        <td>{user.emailAddress}</td>
        <td>{user.userName}</td>
        <td>{user.groups.join(', ')}</td>
        <td>{user.updatedTimestamp}</td>
      </tr>
      { detailOpen &&
        <>
          <tr className="result-table-row-detail">
            <td colSpan="4" className="shadow">
              <UserAccount user={user} />
            </td>
          </tr>
          <tr className="result-table-row-detail-margin">
            <td colSpan="4" className="border-0 p-1 small">
              &nbsp;
            </td>
          </tr>
        </>
      }
    </>
  );
}

export default UserAccountRow;
