import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import OrderDiamondRow from './OrderDiamondRow';

function OrderDiamondList(props){
  const { enableUpdate, orderDiamonds, setOrderDiamondStatus, showStatus } = props;

  if(orderDiamonds === null || orderDiamonds.length === 0){
    return <Alert variant="primary">This order contains no diamonds</Alert>;
  }

  return (
    <div>
      <Alert variant="primary">{orderDiamonds.length} diamonds</Alert>
      <Table striped bordered hover size="lg"
        className="table-lg border border-light"
      >
        <thead>
          <tr>
            { showStatus && <th className="p-1" width="20%">Status</th> }
            <th className="p-1" width="40%">Stone</th>
            <th className="p-1" width="40%">Request / Response</th>
          </tr>
        </thead>
        <tbody>
          {orderDiamonds.map((orderDiamond) =>
            <OrderDiamondRow
              showStatus={showStatus}
              enableUpdate={enableUpdate}
              key={orderDiamond.id}
              orderDiamond={orderDiamond}
              setOrderDiamondStatus={setOrderDiamondStatus}
            />
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default OrderDiamondList;
