import Container from 'react-bootstrap/Container';
import React, { useEffect } from 'react';

function ContentPage(props){
  const { children, title } = props;

  useEffect(() => {
    document.title = title;
  });

  return (
    <Container>
      <h3 className="font-brand">{title}</h3>
      {children}
    </Container>
  );
}

export default ContentPage;
