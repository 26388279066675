import React, {useState} from 'react';
import AdminPage from '../../Layouts/AdminPage';
import UserOrderList from './UserOrderList';
import IfLoggedIn from '../../Components/IfLoggedIn';
import Form from 'react-bootstrap/Form';

function AdminUserOrderIndex(props){
  const { match } = props;
  const { params } = match;
  const [orderType, setOrderType] = useState(params.orderType || 'request');

  function handleSelectChange(e){
    setOrderType(e.currentTarget.value);
  }

  return (
    <AdminPage title="View and Approve Orders">
      <IfLoggedIn>
        <div className="w-50 m-auto p-3">
          <Form.Control as="select" onChange={handleSelectChange} custom>
            <option value="request">Requests</option>
            <option value="response_sent">Responses</option>
          </Form.Control>
        </div>
        <UserOrderList orderType={orderType} />
      </IfLoggedIn>
    </AdminPage>
  );
}

export default AdminUserOrderIndex;
