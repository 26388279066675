import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import ButtonOptions from './ButtonOptions';
import RangeInput from './RangeInput';
import ShapeButtonOptions from './ShapeButtonOptions';
import ColorButtonOptions from './ColorButtonOptions';
import {
  clarityValueLabels,
  colorValueLabels,
  cutValueLabels,
  fancyColorValueLabels,
  fluorescenceValueLabels,
  labValueLabels,
  polishValueLabels,
  shapeValueLabels,
  symmetryValueLabels
} from '../../Util/constants';

const { Control, Group, Label } = Form;

function SearchForm(props){
  const {
    extendedSearchOptions,
    searchParams, setSearchParams,
    perPageCount, setPerPageCount,
    withPricing
  } = props;

  function setSearchParam(fieldName, newValue){
    const newSearchParams = {...searchParams};
    newSearchParams[fieldName] = newValue;
    setSearchParams(newSearchParams);
  }

  function fancySwitchChange(e){
    setSearchParam('fancy', e.currentTarget.checked);
  }

  function labGrownSwitchChange(e){
    setSearchParam('labGrown', e.currentTarget.checked);
  }

  return (
    <Form>
      <ShapeButtonOptions
        title="Shape"
        valueLabels={shapeValueLabels}
        selectedValues={searchParams['shape']}
        setSelectedValues={(values) => setSearchParam('shape', values)}
      />
      <Row className="my-0">
        <Col xs={3} className="text-right">
          <Form.Label title="" htmlFor="switch">White</Form.Label>
        </Col>
        <Col xs={1} className="text-center">
          <Form.Check
            className="mx-2 mt-1"
            type="switch"
            checked={searchParams['fancy']}
            id="fancySwitch"
            label=""
            onChange={fancySwitchChange}
          />
        </Col>
        <Col xs={2} className="text-left">
          <Form.Label title="" htmlFor="switch">Fancy</Form.Label>
        </Col>


        <Col xs={3} className="text-right">
          <Form.Label
            className="pt-1"
            htmlFor="switch"
            title="Mined Diamonds"
          >
            Mined
          </Form.Label>
        </Col>
        <Col xs={1} className="text-center">
          <Form.Check
            className="mx-2 mt-1"
            type="switch"
            checked={searchParams['labGrown']}
            id="labGrownSwitch"
            label=""
            onChange={labGrownSwitchChange}
          />
        </Col>
        <Col xs={2} className="text-left">
          <Form.Label
            className="pt-1"
            htmlFor="switch"
            title="Lab Grown Diamonds"
          >
            Lab&nbsp;Grown
          </Form.Label>
        </Col>
      </Row>
      { !searchParams['fancy'] &&
        <ButtonOptions
          title="Color"
          valueLabels={colorValueLabels}
          selectedValues={searchParams['color']}
          setSelectedValues={(values) => setSearchParam('color', values)}
          selectedButtonVariant='info'
        />
      }
      { searchParams['fancy'] &&
        <ColorButtonOptions
          title="Fancy&nbsp;Color"
          valueLabels={fancyColorValueLabels}
          selectedValues={searchParams['fancyColor']}
          setSelectedValues={(values) => setSearchParam('fancyColor', values)}
          selectedButtonVariant='info'
        />
      }
      <ButtonOptions
        title="Clarity"
        valueLabels={clarityValueLabels}
        selectedValues={searchParams['clarity']}
        setSelectedValues={(values) => setSearchParam('clarity', values)}
      />
      <ButtonOptions
        title="Lab"
        valueLabels={labValueLabels}
        selectedValues={searchParams['lab']}
        setSelectedValues={(values) => setSearchParam('lab', values)}
        selectedButtonVariant='info'
      />
      { extendedSearchOptions &&
        <>
          <ButtonOptions
            title="Cut"
            valueLabels={cutValueLabels}
            selectedValues={searchParams['cutGrade']}
            setSelectedValues={(values) => setSearchParam('cutGrade', values)}
          />
          <ButtonOptions
            title="Polish"
            valueLabels={polishValueLabels}
            selectedValues={searchParams['polish']}
            setSelectedValues={(values) => setSearchParam('polish', values)}
            selectedButtonVariant='info'
          />
          <ButtonOptions
            title="Symmetry"
            valueLabels={symmetryValueLabels}
            selectedValues={searchParams['symmetry']}
            setSelectedValues={(values) => setSearchParam('symmetry', values)}
          />
          <ButtonOptions
            title="Fluorescence"
            valueLabels={fluorescenceValueLabels}
            selectedValues={searchParams['fluorescenceIntensity']}
            setSelectedValues={(values) => setSearchParam('fluorescenceIntensity', values)}
            selectedButtonVariant='info'
          />
        </>
      }
      <Row>
        { withPricing &&
          <>
            <Col xs={1} className="text-right">
              <Label className="small mt-2">Price</Label>
            </Col>
            <Col xs={4}>
              <RangeInput
                lowValue={searchParams['priceRangeMin']}
                highValue={searchParams['priceRangeMax']}
                setLowValue={(val) => setSearchParam('priceRangeMin', val)}
                setHighValue={(val) => setSearchParam('priceRangeMax', val)}
                anyMatch={searchParams['priceRangeAny']}
                setAnyMatch={(values) => setSearchParam('priceRangeAny', values)}
                unit="$"
              />
            </Col>
          </>
        }
        <Col xs={1} className="text-right">
          <Label className="small mt-2">Weight</Label>
        </Col>
        <Col xs={3}>
          <RangeInput
            unit="ct."
            lowValue={searchParams['weightRangeMin']}
            highValue={searchParams['weightRangeMax']}
            setLowValue={(val) => setSearchParam('weightRangeMin', val)}
            setHighValue={(val) => setSearchParam('weightRangeMax', val)}
            anyMatch={searchParams['weightRangeAny']}
            setAnyMatch={(values) => setSearchParam('weightRangeAny', values)}
          />
        </Col>

        { !withPricing &&
          <Col xs={5} className="text-center"> &nbsp; </Col>
        }

        { setPerPageCount !== null &&
          <Col xs={3} className="text-right">
            <Group className="d-flex small">
              <Label className="mr-2 mt-2 font-italic">Per Page</Label>
              <InputGroup size="sm" className="mb-3" style={{width: "12em"}}>
                <Control
                  className="text-right"
                  aria-describedby="inputGroup-per-page"
                  type="text"
                  name="perPageCount"
                  onChange={(e) => setPerPageCount(e.currentTarget.value)}
                  value={perPageCount}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="inputGroup-per-page">items</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Group>
          </Col>
        }
      </Row>
    </Form>
  );
}

export default SearchForm;
