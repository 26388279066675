import React from 'react';
import Image from 'react-bootstrap/Image';

const TOOLTIP = 'This diamond description includes video.'

function VideoIcon(){
  return <Image
    className="row-icon video-icon"
    src="/img/video-icon-256x256.png"
    title={TOOLTIP}
    alt="Video icon" />
}

export default VideoIcon;
